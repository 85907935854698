import * as Realm from 'realm-web'
import {CURRENT_HOST} from '../constants/SERVERS'

const config = {
    id: 'data-hghdt',
}
const app = new Realm.App(config)
const mongoClient = app?.currentUser?.mongoClient('ServerlessInstanceForSequifiArena')
const mongoDB = mongoClient?.db('SequifiArena')
const collection = mongoDB?.collection('sequifi')
// const collection = mongoDB.collection(CURRENT_HOST)

const mongo = {
    loginIntoMongoThroughAPI: async () => {
        const credentials = Realm.Credentials.apiKey(
            'FXC57TeG92Msdz6fufgA8bdAEqgquECVCHBE1YDeiGZ4Ml9kZPYqlJdr68lN1kQA'
        )
        const user = await app.logIn(credentials)
        return user
    },
    addArenaEvent: (data = null) => collection.insertOne(data),
    // Arena Dashboard
    // getOngoingEvent = ({office_id}) => collection.findOne(),
}

export {mongo}
