import moment from 'moment'

import {CURRENT_HOST, FULL_BASE_URL, FULL_IMAGE_URL, HOST_SERVERS} from './SERVERS'
export const APP_VERSION = '1.0.3'
export const PHONE_NUMBER_LENGTH = 10
export const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY
export const DEVELOPMENT_MODE = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
export const ONE_SIGNAL_APP_ID = process.env.REACT_APP_ONE_SIGNAL_APP_ID
export const HIDDEN_SUPER_ADMIN = 'testsuperadmin@sequifi.com'
export const SEQUIFI_ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY
export const SEQUIFI_WEBSITE_URL = 'https://sequifi.com'
export const BASE_URL = FULL_BASE_URL
export const IMAGE_URL = FULL_IMAGE_URL

export const DEV_TEAMs = {
    support: 'support',
    product: 'product',
}
// PUSHER
export const PUSHER_APP_ID = process.env.REACT_APP_PUSHER_APP_KEY
export const MINIMUM_BILLING_OVERDUE_DAYS = 31
export const GOOGLE_ANALYTICS_MEASUREMENT_ID = 'G-14MK2SEJ97'

export const STRIPE_CLIENT_SECRET_TYPE = {
    payment_intent: 'pi',
    payment_setup_intent: 'seti',
}
export const PUSHER_EVENTS = {
    payroll: 'Payroll',
    permission: 'Permission',
    SClearannceReport: 'sclearancereport',
    SClearannceManual: 'sclearancemanualreport',
    projection: 'Projection',
}

export const PUSHER_CHANNELS = {
    staging: 'Sequifi-staging',
    development: 'Sequifi-development',
}

// Amazon S3
export const AMAZON_S3_CONFIG = {
    s3_bucket_url: 'https://dh9m456rx9q0m.cloudfront.net',
    s3_private_bucket_url: 'https://dh9m456rx9q0m.cloudfront.net',
}

export const DEFAULT_CURRENCY = '$'
export const BILLING_PRODUCTS = {
    sequifi_payroll: 1,
    s_clearance: 2,
    sequi_pay: 3,
    sequi_ai: 4,
    user_wise_billing: 5,
    sales_crm: 6,
    arena: 7, // temporary id
}

export const INIT_POSITION_SETUP_DATA = {
    // Position Setup Step 1: Commission
    position_name: null,
    commission_parentage: null,
    commission_parentag_hiring_locked: 0,
    commission_amount_type: 'percent',
    commission_amount_type_locked: 0,
    commission_structure_type: 'fixed',
    commission_parentag_type_hiring_locked: 0,

    // Position Setup Step 2: Upfront
    upfront_status: 1,
    upfront_ammount: 500,
    upfront_ammount_locked: 0,
    calculated_by: 'per sale',
    calculated_locked: 0,
    upfront_system: 'Fixed',
    upfront_system_locked: 0,
    upfront_limit: 1000,

    //   Position Setup Step 3: Deduction
    deduction_status: 1,
    deduction_locked: 0,
    deduction: [
        {
            status: 1,
            cost_center_id: null,
            deduction_type: '$',
            ammount_par_paycheck: null,
        },
    ],
    limit_type: '%',
    limit_ammount: null,
    limit: null,

    // Position Setup Step 4: Overrides
    is_stack: 0,
    override: [
        {
            override_id: 1,
            status: 1,
            override_ammount: null,
            override_ammount_locked: 0,
            type: null,
            override_type_locked: 0,
        },
        {
            override_id: 2,
            status: 1,
            override_ammount: null,
            override_ammount_locked: 0,
            type: null,
            override_type_locked: 0,
        },
        {
            override_id: 3,
            status: 1,
            override_ammount: null,
            override_ammount_locked: 0,
            type: null,
            override_type_locked: 0,
        },
        {
            override_id: 4,
            status: 0,
            override_ammount: null,
            override_ammount_locked: 0,
            type: null,
            override_type_locked: 0,
        },
    ],
    tier_override_status: null,
    sliding_scale: null,
    sliding_scale_locked: null,
    levels: null,
    level_locked: null,

    // Position Setup Step 5: Reconciliation
    reconciliation_status: 1,
    settlement_id: 1,
    settlement_type: 'Duuring M2',
    commission_type_locked: 0,
    commission_withheld_locked: 0,
    commission_withheld: null,
    commission_type: null,
    maximum_withheld: null,
    override_settlement: null,
    clawback_settlement: null,
    stack_settlement: null,

    // Position Setup Step 6: Pay Frequency
    frequency_type_id: null,
}

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY'
export const STRIPE_PAYMENT_LIVE = true
export const STRIPE_PUBLISH_KEY =
    STRIPE_PAYMENT_LIVE &&
    ![
        HOST_SERVERS.sdev,
        HOST_SERVERS.sstage,
        HOST_SERVERS.pdev,
        HOST_SERVERS.pstage,
        HOST_SERVERS.demo,
        HOST_SERVERS.localhost,
        HOST_SERVERS.preprod,
        HOST_SERVERS.flextest,
        HOST_SERVERS.testaveyo,
    ].includes(CURRENT_HOST)
        ? process.env.REACT_APP_STRIPE_PUBLISH_LIVE_KEY
        : process.env.REACT_APP_STRIPE_PUBLISH_TEST_KEY

export const MAIN_POSITIONS = {
    superAdmin: 'superAdmin',
    manager: 'manager',
    setter: 'setter',
    closer: 'closer',
}
export const MAIN_POSITTIONS_ID = {
    closer: '2',
    setter: '3',
}

export const MAIN_DEPARTMENT_ID = {
    management: '1',
    sales: '2',
}

export const getValidDate = (date = null, format = DEFAULT_DATE_FORMAT, getDateObject = false) => {
    const validDate = date
    if (moment(validDate).isValid()) {
        if (getDateObject) return moment(validDate).toDate()
        return moment(validDate).format(format ?? 'YYYY/MM/DD')
    }
    return null
}
export const checkFutureData = (date = null) => {
    return getValidDate(new Date(), 'YYYY-MM-DD 00:00') < getValidDate(date, 'YYYY-MM-DD 00:00')
}

export const TEMPLATE_DYNAMIC_FIELD_FIND_REGEX = /\[(.*?)\]/gim

export const VALIDATION_PATTERN = {
    number: /[0-9]*/gim,
    email: /[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$/gim,
}

export const GENDER_DATA = [
    {name: 'Male', value: 'male'},
    {name: 'Female', value: 'female'},
    {name: 'Not to be mention', value: 'not to be mention'},
]
export const MONTHLY_PAY_DAYS = {
    fifteenAndLastDayOfMonth: {
        name: 'fifteenAndLastDayOfMonth',
        value: '15th and last day of month',
    },
    other: {
        name: 'other',
        value: 'Other',
    },
}

export const BUSINESS_TYPE = [
    {name: 'Sole Proprietoship', value: 'Sole Proprietoship'},
    {name: 'Partnership', value: 'Partnership'},
    {name: 'LLC', value: 'LLC'},
    {name: 'C corp', value: 'C corp'},
    {name: 'S corp', value: 'S corp'},
    {name: 'Nonprofit', value: 'Nonprofit'},
]

export const PAYROLL_FREQUENCY = {
    daily: 'Daily',
    weekly: 'Weekly',
    biWeekly: 'Bi-Weekly',
    monthly: 'Monthly',
    semiMonthly: 'Semi-Monthly',
}

export const LEAD_DAYS_FOR_FINALIZE_PAYROLL = 2
export const WEEK_DAYS = [
    {name: 'Monday', value: 'Monday', dayNumber: 1},
    {name: 'Tuesday', value: 'Tuesday', dayNumber: 2},
    {name: 'Wednesday', value: 'Wednesday', dayNumber: 3},
    {name: 'Thursday', value: 'Thursday', dayNumber: 4},
    {name: 'Friday', value: 'Friday', dayNumber: 5},
    {name: 'Saturday', value: 'Saturday', dayNumber: 6},
    {name: 'Sunday', value: 'Sunday', dayNumber: 7},
]

export const MONTH_DAYS = [
    {name: 'January', value: '01'},
    {name: 'February', value: '02'},
    {name: 'March', value: '03'},
    {name: 'April', value: '04'},
    {name: 'May', value: '05'},
    {name: 'June', value: '06'},
    {name: 'July', value: '07'},
    {name: 'August', value: '08'},
    {name: 'September', value: '09'},
    {name: 'October', value: '10'},
    {name: 'November', value: '11'},
    {name: 'December', value: '12'},
]

export const TEMPLATE_DYNAMIC_SPECIAL_FIELDS = {
    page_break: {key: 'Page Break', val: '[Page_Break]'},
    annexure: {key: 'Annexure', val: 'Annexure A'},
    compensation_plan: {key: 'Compensation Plan', val: '[Compensation_Plan]'},
}
export const W9_CONTENT_SPECIAL_FIELDS = {
    page_break: {key: 'Page Break', val: '[Page Break]'},
    annexure: {key: 'Annexure', val: 'Annexure A'},
    compensation_plan: {key: 'Compensation Plan', val: '[Compensation Plan]'},
}
export const TEMPLATE_DYNAMIC_SIGN_FIELDS = {
    // employee_signature: {key: 'Employee Signature', val: '__________Employee Signature'},
    // employee_initials: {key: 'Employee Initials ', val: '__________Employee Initials    '},
    // employee_manager: {key: 'Employee Manager', val: '__________Employee Manager'},
    // employee_manager_initials: {
    //     key: 'Employee Manager Initials',
    //     val: '__________Employee Manager Initials',
    // },
    employee_signature: {key: 'Employee Signature', val: 's:employee'},
    // employee_initials: {key: 'Employee Initials ', val: 's:employee'},
    // employee_manager: {
    //     key: 'Employee Manager Signature',
    //     val: 's:manager',
    // },
    // employee_manager_initials: {
    //     key: 'Employee Manager Initials',
    //     val: 's:manager',
    // },
}
export const TEMPLATE_EXTRA_FIELDS = {
    employee_signature: {key: 'Text Entry', val: 'text_entry'},
}
export const EMAIL_TEMPLATE_DYNAMIC_FIELDS = {
    recipient_name: {key: 'Employee_Name'},
    recipient_position: {key: 'Employee_Position'},
    office_location: {key: 'Office_Location'},
    company_website: {key: 'Company_Website'},
    company_address: {key: 'Company_Address'},
    company_name: {key: 'Company_Name'},
    // document_access_password: {key: 'Document_Access_Password'},
}

export const EDIT_EMAIL_TEMPLATE_FIELDS = {
    recipient_name: {key: 'Employee_Name'},
    company_name: {key: 'Company_Name'},
    employee_user_name: {key: 'Employee_User_Name'},
    employee_id: {key: 'Employee_Id'},
}

export const REPORTS_DURATION_DROPDOWN_LIST = [
    {name: 'This Week', value: 'this_week'},
    {name: 'This Month', value: 'this_month'},
    {name: 'This Year', value: 'this_year'},
    {name: 'Last Year', value: 'last_year'},
    {name: 'Last 12 Months', value: 'last_12_months'},
    {name: 'This Quarter', value: 'this_quarter'},
    {name: 'Last Quarter', value: 'last_quarter'},
    {name: 'Custom', value: 'custom'},
]

export const Dashboard_DURATION_DROPDOWN_LIST = [
    {name: 'This Week', value: 'this_week'},
    {name: 'This Month', value: 'this_month'},
    {name: 'This Year', value: 'this_year'},
    {name: 'Last 12 Months', value: 'last_12_months'},
    {name: 'This Quarter', value: 'this_quarter'},
]

export const BANKING_TYPE_OF_ACCOUNT = [
    {name: 'Savings', value: 'savings'},
    {name: 'Checking', value: 'checking'},
]
export const COMPANY_LETTER_PAD_TEMPLATE = ({
    width,
    company_logo,
    company_name,
    company_address,
}) => {
    return `
    <div style="
        width: ${width}px; 
        padding: 10;
        display: 'flex';
        align-items: 'center';
        justify-content: 'space-between';
    ">
    <img src='${company_logo}' style="height: 100px; width:100px;" />
        <div style="display: flex; flex-direction: column; align-items: flex-end">
            <strong style="text-transform: uppercase; font-size: 16">${company_name}</strong>
            <p>Address: ${company_address}</p>
        </div>
    </div>
    `
}

export const INTEGRATIONS_ID = {
    lgcy: 1,
    hubspot: 2,
    everee: 3,
    jobNimbuss: 4,
    sClearance: 5,
    sequiAi: 6,
    salesCRM: 7,
}

export const EVENTS_TYPES = [
    {name: 'Career Fair', value: 'Career Fair'},
    {name: 'Meeting', value: 'Meeting'},
    {name: 'Company Event', value: 'Company Event'},
    {name: 'Training', value: 'Training'},
    {name: 'Other', value: 'Other'},
]

export const getDaysArray = () => {
    const daysArray = []
    for (let index = 0; index < 28; index++) {
        daysArray.push({name: index + 1, value: index + 1})
    }
    return daysArray
}

export const HIRING_PROCESS_STATUS = {
    // Lead Status
    followUp: 'Follow Up',
    interviewScheduled: 'Interview Scheduled',
    interviewRescheduled: 'Interview Rescheduled',
    interviewDone: 'Interview Done',
    rejected: 'Rejected',
    Leadhired: 'Hired',

    // Onboarding Status
    accepted: 1,
    declined: 2,
    approved: 3, // Not In Use
    offerLetterSent: 4,
    offerExpired: 5,
    requestedChange: 6,
    onboarding: 7,
    draft: 8,
    onboardFollowUp: 9,
    notInterested: 10, // Not In Use
    offerLetterRejected: 11,
    offerLetterResent: 12,
    offerLetterAccepted: 13,
    active: 14,
    adminRejected: 15,
}
export const SINGLE_USER_PAYROLL_PROCESS_STATUS = {
    pending: 1,
    finilize: 2,
    paid: 3,
    nextPayroll: 4,
    skipped: 5,
    moveToRecon: 6,
    addedFromRecon: 7,
}
export const LEAD_PROCESS_STATUS = [{name: 'Interview Done', value: 'Interview Done'}]

export const DYNAMIC_FIELD_TYPE = [
    {name: 'Text', value: 'text'},
    {name: 'Number', value: 'number'},
    {name: 'Date', value: 'date'},
    {name: 'Phone Number', value: 'phone number'},
    {name: 'Dropdown', value: 'dropdown'},
]

export const HIRE_FIELD_KEYS = {
    id: 'id',
    first_name: 'first_name',
    last_name: 'last_name',
    email: 'email',
    work_email: 'work_email',
    mobile_no: 'mobile_no',

    state_id: 'state_id',
    office_id: 'office_id',
    is_manager: 'is_manager',
    is_manager_effective_date: 'is_manager_effective_date',
    self_gen_accounts: 'self_gen_accounts',
    state_name: 'state_name',
    city_name: 'city_name',
    location: 'location',
    department_id: 'department_id',
    position_id: 'position_id',
    sub_position_id: 'sub_position_id',
    deduction: 'deduction',
    manager_id: 'manager_id',
    manager_id_effective_date: 'manager_id_effective_date',
    effective_date: 'effective_date',
    existing_employee_new_manager_id: 'existing_employee_new_manager_id',
    additional_locations_effective_date: 'additional_locations_effective_date',
    recruiter_id: 'recruiter_id',
    recruiter_name: 'recruiter_name',
    department_name: 'department_name',
    manager_name: 'manager_name',
    team_id: 'team_id',
    position_name: 'position_name',
    additional_locations: 'additional_locations',
    additional_recruter: 'additional_recruter',

    pay_type_and_rate_effective_date: 'pay_type_and_rate_effective_date',
    pay_type: 'pay_type',
    pay_rate: 'pay_rate',
    pto_hours: 'pto_hours',
    pto_hours_effective_date: 'pto_hours_effective_date',
    unused_pto_expires: 'unused_pto_expires',
    expected_weekly_hours: 'expected_weekly_hours',
    overtime_rate: 'overtime_rate',

    employee_compensation: 'employee_compensation',
    commission_effective_date: 'commission_effective_date',
    commission: 'commission',
    commission_type: 'commission_type',
    commission_selfgen: 'commission_selfgen',
    commission_selfgen_type: 'commission_selfgen_type',
    commission_selfgen_effective_date: 'commission_selfgen_effective_date',
    redline_amount_type: 'redline_amount_type',
    redline: 'redline',
    redline_type: 'redline_type',
    redline_effective_date: 'redline_effective_date',
    redline_data: 'redline_data',
    upfront_effective_date: 'upfront_effective_date',
    upfront_pay_amount: 'upfront_pay_amount',
    upfront_sale_type: 'upfront_sale_type',
    withheld_effective_date: 'withheld_effective_date',
    withheld_amount: 'withheld_amount',
    withheld_type: 'withheld_type',
    self_gen_commission_effective_date: 'self_gen_commission_effective_date',
    self_gen_commission: 'self_gen_commission',
    self_gen_redline_amount_type: 'self_gen_redline_amount_type',
    self_gen_redline: 'self_gen_redline',
    self_gen_redline_type: 'self_gen_redline_type',
    self_gen_redline_data: 'self_gen_redline_data',
    self_gen_upfront_effective_date: 'self_gen_upfront_effective_date',
    self_gen_upfront_amount: 'self_gen_upfront_amount',
    self_gen_upfront_type: 'self_gen_upfront_type',

    override_effective_date: 'override_effective_date',
    direct_overrides_amount: 'direct_overrides_amount',
    direct_overrides_type: 'direct_overrides_type',
    indirect_overrides_amount: 'indirect_overrides_amount',
    indirect_overrides_type: 'indirect_overrides_type',
    office_overrides_amount: 'office_overrides_amount',
    office_overrides_type: 'office_overrides_type',
    office_stack_overrides_amount: 'office_stack_overrides_amount',
    additional_office_override: 'additional_office_override',

    probation_period: 'probation_period',
    offer_include_bonus: 'offer_include_bonus',
    hiring_bonus_amount: 'hiring_bonus_amount',
    date_to_be_paid: 'date_to_be_paid',
    period_of_agreement: 'period_of_agreement',
    period_of_agreement_start_date: 'period_of_agreement_start_date',
    end_date: 'end_date',
    offer_expiry_date: 'offer_expiry_date',
    is_background_verificaton: 'is_background_verificaton',
}

export const MISSING_KEYS = {
    pid: 'pid',
    install_partner: 'install_partner',
    customer_signoff: 'customer_signoff',
    gross_account_value: 'gross_account_value',
    sales_rep_email: 'sales_rep_email',
    epc: 'epc',
    net_epc: 'net_epc',
    dealer_fee_percentage: 'dealer_fee_percentage',
    customer_name: 'customer_name',
    customer_state: 'customer_state',
    location_code: 'location_code',
    rep_name: 'sales_rep_name',
    closer: 'closer',
    setter: 'setter',
    m1: 'm1',
    m2: 'm2',
    m1_m2: 'm1,m2',
    m2_m1: 'm2,m1',
    location: 'Location',
    locationRedline: 'Location_redline',
    setterId: 'setter_id',
    newRep: 'sales_rep_new',
    taxInfo: 'tax_information',
    kw: 'kw',

    workEmail: 'work_email',
    bankDetailArray: ['name_of_bank', 'routing_no', 'account_no', 'type_of_account'],
    taxDetailArray: ['tax_information'],

    new_sales_rep: 'new_sales_rep',
    sales_rep_email_saleapproval: 'sales_rep_email_saleapproval',
    new_sales_setter: 'new_sales_setter',
}

export const GLOBAL_SEARCH_TYPE = [
    {name: 'People', value: 'people'},
    {name: 'Payroll', value: 'payroll'},
]
export const GLOBAL_PAGE_LIMT = [
    {key: '10', value: '10'},
    {key: '25', value: '25'},
    {key: '50', value: '50'},
    {key: '75', value: '75'},
    {key: '100', value: '100'},
]
export const REQUEST_APPROVAL_TYPE_DROPDOWN_VALUES = [
    {name: 'Payroll dispute', value: 'Payroll dispute'},
    {name: 'Reimbursement', value: 'Reimbursement'},
    {name: 'Bonus', value: 'Bonus'},
    {name: 'Fine/Fee', value: 'Fine/Fee'},
    {name: 'Incentive', value: 'Incentive'},
    {name: 'Advance', value: 'Advance'},
]

export const ONE_TIME_PAYMENT_HISTORY_DROPDOWN_VALUES = [
    {name: 'Payroll dispute', value: 'Payroll dispute'},
    {name: 'Reimbursement', value: 'Reimbursement'},
    {name: 'Advance', value: 'Advance'},
    {name: 'Bonus', value: 'Bonus'},
    {name: 'Fine/Fee', value: 'Fine/Fee'},
    {name: 'Incentive', value: 'Incentive'},
]
export const REQUEST_STATUS_INFO = [
    {
        name: 'Pending',
        value: "Pending Awaiting manager's review. Your request is in line to be approved or declined",
        headClass: 'text-cmDisButton',
    },
    {
        name: 'Approved',
        value: "Green light from the manager! It's now with the admin for the next steps.",
        headClass: 'text-cmOrange',
    },
    {
        name: 'Declined',
        value: "Sorry, your request didn't get the go-ahead.",
        headClass: 'text-cmError',
    },
    {
        name: 'Scheduled',
        value: ' The admin has approved, and your request is slotted for the next payroll cycle.',
        headClass: 'text-cminfo',
    },
    {name: 'Paid', value: 'All done! Your request has been paid!', headClass: 'text-cmSuccess'},
]

export const AUTHENTICATION_OPTIONS = [
    {
        name: 'User Name and Password',
        value: 'user_name/password',
    },
    {
        name: 'Token',
        value: 'token',
    },
]
export const PROTOCAL_OPTIONS = [
    {
        name: 'SMTP',
        value: 'smtp',
    },
]
export const PROVIDER_OPTIONS = [
    {
        name: 'SendLayer',
        value: 'SendLayer',
    },
    {
        name: 'SMTP.com',
        value: 'custom',
    },
    {
        name: 'Brevo (Formerly Sendinblue)',
        value: 'Brevo (Formerly Sendinblue)',
    },
    {
        name: 'Mailgun',
        value: 'Mailgun',
    },
    {
        name: 'SendGrid',
        value: 'SendGrid',
    },
    {
        name: 'Amazon SES',
        value: 'Amazon SES',
    },
    {
        name: 'Mailersend',
        value: 'Mailersend',
    },
]
export const SECURITY_PROTOCOL_OPTIONS = [
    {
        name: 'SSL',
        value: 'SSL',
    },
    {
        name: 'TLS',
        value: 'TLS',
    },
]
export const PENDING_INSTALL_FILTER = {
    showOnlyAccounts: [
        {
            name: 'With No M1 Date',
            value: 'with_no_m1_date',
        },
        {
            name: 'With M1 Date',
            value: 'with_m1_date',
        },
    ],
    ageOfAccounts: [
        {
            name: '5 to 10',
            value: '5-10',
        },
        {
            name: '10 to 30',
            value: '10-30',
        },
        {
            name: '30 to 50',
            value: '30-50',
        },
        {
            name: '50 & above',
            value: '50-above',
        },
    ],
}

export const NEW_SEQUIDOC_FAQS = [
    {
        id: 1,
        heading: 'What should I check in the Offer Letter?',
        content:
            'It is best to always read your entire offer letter thoroughly.  Ensure your compensation values are present and correct.  If a value is missing or incorrect make a change request.  A hiring manager or admin will review it and get back to you shortly.  If your offer includes working terms and expectations it is always best to review all those details as well to ensure you fully understand the expectations the company has.',
    },
    {
        id: 2,
        heading: 'Are all documents necessary?',
        content:
            'No.  Only documents marked with a red asterisk * are mandatory documents.  You should review all documents sent to you to ensure you complete any that apply to you, however not all documents may be necessary.',
    },
    {
        id: 3,
        heading: 'Can I accept the offer before uploading documents?',
        content:
            'Yes.  You can accept your offer letter at any point in the process.  Documents provided here can be filled out in any order you wish.  However, you will not be able to complete the offer packet unless all mandatory documents are completed and the company will not be able to hire you until the offer packet is complete.        ',
    },
    {
        id: 4,
        heading: 'What if I find errors or have questions about the documents?',
        content:
            'If you find any errors in your offer, or if you have any questions, contact, your recruiter or administrator immediately and discuss the error with them.  They will be able to make any changes necessary and send you an updated offer if necessary.',
    },
    {
        id: 5,
        heading: 'Is my personal information secure during the upload process?',
        content: ` Absolutely.  Sequifi holds to the highest standards of data safety and security, ensuring your documents and personal information is always secure.`,
    },
    {
        id: 6,
        heading: 'Who can I contact for technical issues or support?',
        content: `Reach out to your administrator for any questions or use the "Support" tab within your Sequifi portal to view FAQs, How-To's, and submit support tickets.`,
    },
]

export const NEW_SEQUIDOC_DOCUMENT_STATUS = {
    rejected: 3,
    signed: 2,
    adminRejected: 15,
}

export const SEQUIDOC_DOCUMENT_FILTER_OPTIONS = [
    {
        name: 'All Docs',
        value: 'all',
    },
    {
        name: 'Completed',
        value: 'completed',
    },
    {
        name: 'InCompleted',
        value: 'inCompleted',
    },
    {
        name: 'Mandatory',
        value: 'mandatory',
    },
    {
        name: 'Uploaded',
        value: 'uploaded',
    },
    {
        name: 'Signed',
        value: 'signed',
    },
]
export const ORGANIZATION_TYPE = [
    {name: 'All', value: ''},
    {name: 'Manager', value: 'Manager'},
    {name: 'Is manager', value: 'is manager'},
    {name: 'Self Gen', value: 'Self Gen'},
    {name: 'Position', value: 'Position'},
    {name: 'Team', value: 'Team'},
    {name: 'Additional Location', value: 'Additional Location'},
]
export const HISTORY_OVERRIDE_TYPE = [
    {name: 'All', value: ''},
    {name: 'Direct', value: 'Direct'},
    {name: 'Indirect', value: 'Indirect'},
    {name: 'Office', value: 'Office'},
    {name: ' Office Stack', value: 'Office Stack'},
]

export const HISTORY_WAGES_TYPE = [
    {name: 'All', value: ''},
    {name: 'Pay Type', value: 'pay_type'},
    {name: 'Pay Rate', value: 'pay rate'},
    {name: 'PTO Hours', value: 'pto hours'},
]
export const REDLINE_TYPE = [
    {name: 'All', value: ''},
    {name: 'Commission', value: 'Commission'},
    {name: 'Fixed Redline', value: 'Fixed Redline'},
    {name: 'Location Redline', value: 'Location Redline'},
    {name: 'Upfront', value: 'Upfront'},
    {name: 'Withheld', value: 'Withheld'},
    {name: 'Self Gen Commission', value: 'Self Gen Commission'},
]
export const SCLEARANCE_STATUS = [
    {name: 'Mail Sent', value: 'Mail Sent'},
    {name: 'Pending Verification', value: 'Pending Verification'},
    {name: 'In Progress', value: 'In Progress'},
    {name: 'Verification SuccessFul', value: 'Verification SuccessFul'},
    {name: 'Verification Failed', value: 'Verification Failed'},
    {name: 'Manual Verification Pending', value: 'Manual Verification Pending'},
    {name: 'Approval Pending', value: 'Approval Pending'},
    {name: 'Report Pending', value: 'Report Pending'},
    {name: 'Canceled', value: 'Canceled'},
    {name: 'Approved', value: 'Approved'},
    {name: 'Declined', value: 'Declined'},
]
export const ACTION_ITEMS_TYPE = {
    missingSetter: 'Missing Setter',
    newRequest: 'New Request',
    reviewAndSignDocument: 'New Document',
    taxInformation: 'Tax Information',
    commission: 'Commission Changes',
    selfGenCommission: 'Self Gen Commission Changes',
    fixedRedline: 'Fixed Redline Changes',
    locationRedline: 'Location Redline Changes',
    upfront: 'Upfront Changes',
    override: 'Overide Changes',
    position: 'Position Changes',
    withheld: 'Withheld Changes',
}
export const MY_OVERRIDE_TYPE = [
    {name: 'All', value: ''},
    {name: 'Direct', value: 'Direct'},
    {name: 'Indirect', value: 'Indirect'},
    {name: 'Office', value: 'Office'},
    {name: 'Manual', value: 'Manual'},
    {name: 'Stack', value: 'Stack'},
]

const STRIPE_PAYMENT_INTENT_STATUS = {
    succeeded: 'succeeded',
    requires_payment_method: 'requires_payment_method',
    payment_failed: 'payment_failed',
    requires_action: 'requires_action',
    processing: 'processing',
}

export const STRIPE_PAYMENT_INTENT_STATUS_ICON = {
    succeeded: 'bi bi-check-circle-fill text-success',
    requires_payment_method: 'bi bi-x-circle-fill text-danger',
    payment_failed: 'bi bi-x-circle-fill text-danger',
    requires_action: 'bi bi-envelope-check text-cmBlue-Crayola',
    processing: 'bi bi-arrow-repeat text-cmBlue-Crayola',
}

export const STRIPE_PAYMENT_STATUS_ID = {
    unpaid: 0,
    paid: 1,
    processing: 3,
}
export const STRIPE_PAYMENT_STATUS = [
    {
        status_id: STRIPE_PAYMENT_STATUS_ID.unpaid,
        status: 'Unpaid',
        stripe_status: 'Payment failed',
        stripe_status_code: [
            STRIPE_PAYMENT_INTENT_STATUS.requires_payment_method,
            STRIPE_PAYMENT_INTENT_STATUS.payment_failed,
        ],
    },
    {
        status_id: STRIPE_PAYMENT_STATUS_ID.paid,
        status: 'Paid',
        stripe_status: 'Payment successful',
        stripe_status_code: [STRIPE_PAYMENT_INTENT_STATUS.succeeded],
    },
    {
        status_id: STRIPE_PAYMENT_STATUS_ID.underVerification,
        status: 'Under verification',
        stripe_status: 'Under verification',
        stripe_status_code: [STRIPE_PAYMENT_INTENT_STATUS.requires_action],
    },
    {
        status_id: STRIPE_PAYMENT_STATUS_ID.processing,
        status: 'Processing',
        stripe_status: 'Processing',
        stripe_status_code: [STRIPE_PAYMENT_INTENT_STATUS.processing],
        iconClass: 'bi bi-envelope-check-fill text-cmBlue-Crayola',
    },
]

//For setup Custom Payroll Fields
export const CUSTOMFIELD_WORKER_TYPE = [
    {name: 'Contractor', value: 'Contractor'},
    {name: 'Employee', value: 'Employee'},
    {name: 'Both', value: 'Both'},
]
export const WORKER_TYPE = [
    {name: 'Contractor', value: 'Contractor', typeValue: '1099'},
    {name: 'Employee', value: 'Employee', typeValue: 'w2'},
]
export const EVEREE_ONBOARDING_STATUS = {
    DISMISS: 'DISMISS',
    MESSAGE_PORT_REGISTERED: 'MESSAGE_PORT_REGISTERED',
    WORKER_ONBOARDING_COMPLETE: 'WORKER_ONBOARDING_COMPLETE',
}
export const WAGES_PAY_TYPES = [
    {name: 'Salary', value: 'Salary'},
    {name: 'Hourly', value: 'Hourly'},
]

export const WAGES_UNUSED_PTO_TYPES = [
    {name: 'Expires Monthly', value: 'Expires Monthly'},
    {name: 'Expires Annually', value: 'Expires Annually'},
    {name: 'Accrues Continuously', value: 'Accrues Continuously'},
    {name: 'Both', value: 'Both'},
]
export const PAYMENT_TYPE = [
    {name: 'Regular Salary', value: 'Regular Salary'},
    {name: 'Regular Hourly', value: 'Regular Hourly'},
    {name: 'Overtime Hourly', value: 'Overtime Hourly'},
    {name: 'Bonus', value: 'Bonus'},
    {name: 'Tips', value: 'Tips'},
    {name: 'Commission', value: 'Commission'},
    {name: 'Contractor Pay', value: 'Contractor Pay'},
    {name: 'Separation', value: 'Separation'},
    {name: 'Reimbursement (non-taxed)', value: 'Reimbursement (non-taxed)'},
    {name: 'Per Diem', value: 'Per Diem'},
    {name: 'Vacation', value: 'Vacation'},
    {name: 'Sick', value: 'Sick'},
    {name: 'PTO', value: 'PTO'},
    {name: 'Holiday', value: 'Holiday'},
    {name: 'Advance', value: 'Advance'},
    {name: 'Loan', value: 'Loan'},
    {name: 'ISO Disqualifying Disposition', value: 'ISO Disqualifying Disposition'},
    {name: 'ISO Qualifying Disposition', value: 'ISO Qualifying Disposition'},
    {name: 'NSO Transaction', value: 'NSO Transaction'},
    {name: 'RSU Transaction', value: 'RSU Transaction'},
]

//for Sales CRM
export const SALES_CRM_PIPELINE = [
    {name: 'Prospect ID', value: 'prospect_id'},
    {name: 'Homeowner ID', value: 'homeowner_id'},
    {name: 'Proposal ID', value: 'proposal_id'},
    {name: 'Product', value: 'product'},
    {name: 'Gross Account value', value: 'gross_account_value'},
    {name: 'Installer', value: 'installer'},
    {name: 'Dealer Fee %', value: 'dealer_fee'},
    {name: 'Customer State', value: 'customer_state'},
]
export const SALES_CRM_TIMER = [
    {name: 'All', value: 'all'},
    {name: 'On Time', value: 'ontime'},
    {name: 'Warning', value: 'warning'},
    {name: 'Danger', value: 'danger'},
]

export const CLOCK_FORMAT = [
    {
        id: 0,
        name: '12 Hour',
        value: '12-Hour',
        formatValue: '12',
    },
    {
        id: 1,
        name: '24 Hour',
        value: '24-Hour',
        formatValue: '24',
    },
]

export const DURATIONS_LIST = [
    {
        name: 'None',
        value: '0',
    },
    {
        name: '30 Mins',
        value: '1',
    },
    {
        name: '60 Mins',
        value: '2',
    },
    {
        name: '90 Mins',
        value: '3',
    },
    {
        name: '120 Mins',
        value: '4',
    },
]

export const MIN_SCHEDULE_HOUR = 8
export const WEEKLY_SCHEDULE_HOUR = 40
export const LEADERBOARD_DURATION_DROPDOWN_LIST = [
    {name: 'All', value: ''},
    {name: 'Today', value: 'today'},
    {name: 'Yesterday', value: 'yesterday'},
    {name: 'This Week', value: 'this_week'},
    {name: 'Last Week', value: 'last_week'},
    {name: 'This Month', value: 'this_month'},
    {name: 'Last Month', value: 'last_month'},
    {name: 'This Year', value: 'this_year'},
    {name: 'Last Year', value: 'last_year'},
    {name: 'Last 12 Months', value: 'last_12_months'},
    {name: 'Custom', value: 'custom'},
]
export const YEARLY_OVERVIEW = [
    {name: 'All Types', value: 'all'},
    {name: 'Payments', value: 'payments'},
    {name: 'Accounts', value: 'accounts'},
]

export const COMPANY_DURATION_DROPDOWN_LIST = [
    {name: '2020', value: '2020'},
    {name: '2021', value: '2021'},
    {name: '2022', value: '2022'},
    {name: '2023', value: '2023'},
    {name: '2024', value: '2024'},
    {name: 'Last 12 Months', value: 'last_12_months'},
]

export const EXCEL_IMPORT_CATEGORY = {
    sale: 'Sale',
    user: 'User',
    lead: 'Lead',
    job: 'Job',
}
export const WAGES_TIMESHEET_PERIOD = [
    {name: 'Current Pay Period', value: 'current'},
    {name: 'Previous Pay Period', value: 'previous'},
]

export const ACCOUNT_WITH = [
    {name: 'M1 Date', value: 'm1_date'},
    {name: 'M2 Date', value: 'm2_date'},
    {name: 'M1 & M2 Date', value: 'm1_date_m2_date'},
    {name: 'Cancel Date', value: 'cancel_date'},
    {name: 'M1 Paid', value: 'm1_paid'},
    {name: 'M2 Paid', value: 'm2_paid'},
]

export const MULTITENANT_BILLING_FREQUENCY = [
    {name: 'Monthly', value: 'monthly'},
    {name: 'Quarterly', value: 'quarterly'},
    {name: 'Half Yearly', value: 'half_yearly'},
    {name: 'Annually', value: 'annually'},
]

export const MULTITENANT_CLIENT_DIRECTORY_DROPDOWN_LIST = [
    {name: 'This Month', value: 'this_month'},
    {name: 'Last Months', value: 'last_months'},
    {name: 'This Quarter', value: 'this_quarter'},
    {name: 'Last Quarter', value: 'last_quarter'},
    {name: 'This Year', value: 'this_year'},
    {name: 'Last Year', value: 'last_year'},
]
export const MULTITENANT_DASHBOARD_CHART = [
    {name: '6 Month', value: 'six_month'},
    {name: '12 Months', value: 'twelve_months'},
]
//Arena
export const ARENA_EVENT_TYPES = {
    ladder: 'Ladder',
    bracket: 'Bracket',
    survivor: 'Survivor',
    performance: 'Performance',
    champion: 'Champion',
    battle: 'Battle',
    classicRoulette: 'Classic Roulette',
    russianRoulette: 'Russian Roulette',
}

export const ARENA_REWARD_TYPES = [
    {name: 'Cash', value: 'Cash'},
    {name: 'Item', value: 'Item'},
]

export const ARENA_NEW_EVENT_TYPE_META_DATA = [
    {
        name: ARENA_EVENT_TYPES.ladder,
        value: ARENA_EVENT_TYPES.ladder,
        icon: '/media/icons/duotune/arena/ladder.svg',
    },
    {
        name: ARENA_EVENT_TYPES.bracket,
        value: ARENA_EVENT_TYPES.bracket,
        icon: '/media/icons/duotune/arena/bracket.svg',
    },
    {
        name: ARENA_EVENT_TYPES.survivor,
        value: ARENA_EVENT_TYPES.survivor,
        icon: '/media/icons/duotune/arena/survivor.svg',
    },
    {
        name: ARENA_EVENT_TYPES.performance,
        value: ARENA_EVENT_TYPES.performance,
        icon: '/media/icons/duotune/arena/performance.svg',
    },
    {
        name: ARENA_EVENT_TYPES.champion,
        value: ARENA_EVENT_TYPES.champion,
        icon: '/media/icons/duotune/arena/champion.svg',
        place_1_icon: '/media/icons/duotune/arena/Place1.svg',
        place_2_icon: '/media/icons/duotune/arena/Place2.svg',
        place_3_icon: '/media/icons/duotune/arena/Place3.svg',
        place_4_icon: '/media/icons/duotune/arena/place4.svg',
    },
    {
        name: ARENA_EVENT_TYPES.battle,
        value: ARENA_EVENT_TYPES.battle,
        icon: '/media/icons/duotune/arena/battle.svg',
    },
    {
        name: ARENA_EVENT_TYPES.classicRoulette,
        value: ARENA_EVENT_TYPES.classicRoulette,
        icon: '/media/icons/duotune/arena/classic_roulette.svg',
    },
    {
        name: ARENA_EVENT_TYPES.russianRoulette,
        value: ARENA_EVENT_TYPES.russianRoulette,
        icon: '/media/icons/duotune/arena/classic_roulette.svg',
    },
]

export const getArenaEventMetaData = (eventType) => {
    return ARENA_NEW_EVENT_TYPE_META_DATA?.find((item) => item?.name == eventType)
}

export const ARENA_NEW_EVENT_MODE = [
    {name: 'Individual', value: 'Individual'},
    {name: 'Teams', value: 'Teams'},
]

export const ARENA_WEEK_DAYS = [
    {name: 'Monday', value: 1},
    {name: 'Tuesday', value: 2},
    {name: 'Wednesday', value: 3},
    {name: 'Thursday', value: 4},
    {name: 'Friday', value: 5},
    {name: 'Saturday', value: 6},
    {name: 'Sunday', value: 7},
]

export const ARENA_COMPETITION_DURATION = [
    {name: 'Set by no. of days', value: 'set_by_no_of_days'},
    {name: 'Set by exact date', value: 'set_by_exact_date'},
]
export const ARENA_COMPETITION_CUTOFF_WIN_CRITERIA = [
    {name: 'Percentage', value: 'percentage'},
    {name: 'Points', value: 'points'},
]

export const ARENA_ADD_PARTICIPANTS_POSITIONS = [
    {name: 'All Positions', value: 'All Positions'},
    {name: 'Setter', value: 'Setter'},
    {name: 'Closer', value: 'Closer'},
]

export const ARENA_ASSIGN_MATCHES = [
    {name: 'Manual', value: 'Manual'},
    {name: 'Random', value: 'Random'},
]
