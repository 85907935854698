import {KTSVG} from '../../../../../_metronic/helpers'
import CustomBadge from '../../../../../customComponents/customBadge/CustomBadge'
import Show from '../../../../../customComponents/show/Show'

const ArenaCreateEventTopCard = ({
    visible = true,
    eventData,
    showParticipants = false,
    eventMetaData,
}) => {
    return (
        <Show>
            <Show.When isVisible={visible}>
                <div className='d-flex align-items-center flex-row mb-32px'>
                    <label>
                        <KTSVG
                            path={eventMetaData?.icon}
                            svgClassName='cursor-pointer'
                            svgStyle={{width: '54px', height: '54px'}}
                        />
                    </label>

                    <div className='d-flex flex-column ps-14px '>
                        <div className='mb-2'>
                            <span className='text-md-bold text-sqGray900 '>
                                {eventData?.event_name}
                            </span>
                        </div>
                        <div className='d-flex gap-8px'>
                            <div>
                                <CustomBadge
                                    label={eventData?.event_type}
                                    textColor='sqGray700'
                                    fontWeight={700}
                                    fontSize={12}
                                    px={8}
                                    py={2}
                                    backgroundColor={'sqGray100'}
                                    opacity={false}
                                    rounded={true}
                                />
                            </div>
                            <div>
                                <CustomBadge
                                    label={eventData?.event_mode}
                                    textColor='sqGray700'
                                    fontWeight={700}
                                    fontSize={12}
                                    px={8}
                                    py={2}
                                    backgroundColor={'sqGray100'}
                                    opacity={false}
                                    rounded={true}
                                />
                            </div>
                            <Show>
                                <Show.When isVisible={showParticipants}>
                                    <div>
                                        <CustomBadge
                                            label={`${eventData?.users?.length ?? 0} Participants`}
                                            textColor='sqGray700'
                                            fontWeight={700}
                                            fontSize={12}
                                            px={8}
                                            py={2}
                                            backgroundColor={'sqGray100'}
                                            opacity={false}
                                            rounded={true}
                                        />
                                    </div>
                                </Show.When>
                            </Show>
                        </div>
                    </div>
                </div>
            </Show.When>
        </Show>
    )
}

export default ArenaCreateEventTopCard
