import React, {useCallback, useMemo} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import CustomModal2 from '../../../../../../customComponents/customModal/CustomModal2'
import CustomInputChipOpen from '../../../../../../customComponents/customInputs/customInput/inputChip/CustomInputChipOpen'
import CustomBadge from '../../../../../../customComponents/customBadge/CustomBadge'
import {getPositionsSelector} from '../../../../../../redux/selectors/SettingsSelectors'
import {useSelector} from 'react-redux'
import CustomAutoCompleteDropdown from '../../../../../../customComponents/customInputs/customAutoCompleteDropdown/CustomAutoCompleteDropdown'
import Show from '../../../../../../customComponents/show/Show'
import _ from 'lodash'
import CustomLoader from '../../../../../../customComponents/customLoader/CustomLoader'
import {ARENA_EVENT_KEYS} from './AddArenaEvent'

const AddParticipants = ({
    foundedUsers,
    updateEventData,
    eventData = null,
    arenaEventErrorData = null,
    selectedOffices = null,
    setSelectedOffices = null,
    selectedPositions = null,
    setSelectedPositions = null,
    officeList,
    viewParticipants,
    setViewParticipants,
    getUserBasedOnOfficeAndPositionLoading,
}) => {
    const allPositions = useSelector(getPositionsSelector)
    const positionList = useMemo(() => {
        let list = []

        list.push({name: 'All Positions', value: 'all'})
        allPositions.map((i) => {
            list.push({name: i.position_name, value: i.id})
        })
        return list
    }, [allPositions])

    // select user from or option
    const onUserOptionPress = useCallback(
        (item) => {
            let isUserAlredySelected =
                eventData?.users?.length > 0
                    ? eventData?.users?.some((val) => val?.user_id == item?.user_id)
                    : false
            let userData = _.cloneDeep(eventData?.users) ?? []
            let data = isUserAlredySelected
                ? userData?.filter((it) => item?.user_id != it?.user_id)
                : [...eventData?.users, item]

            updateEventData({
                target: {
                    name: ARENA_EVENT_KEYS.users,
                    value: data,
                },
            })
        },
        [eventData?.users, updateEventData]
    )

    const onOfficeSelect = useCallback(
        (e) => {
            if (e?.target?.value?.length > 0) {
                if (e?.selectedOption?.value == 'all') {
                    setSelectedOffices(
                        selectedOffices?.some((item) => item == 'all') ? [] : ['all']
                    )
                } else {
                    setSelectedOffices(e?.target?.value?.filter((item) => item != 'all'))
                }
            } else {
                setSelectedOffices(['all'])
            }
        },
        [selectedOffices, setSelectedOffices]
    )

    const onPositionSelect = useCallback(
        (e) => {
            if (e?.target?.value?.length > 0) {
                if (e?.selectedOption?.value == 'all') {
                    setSelectedPositions(
                        selectedPositions?.some((item) => item == 'all') ? [] : ['all']
                    )
                } else {
                    setSelectedPositions(e?.target?.value?.filter((item) => item != 'all'))
                }
            } else {
                setSelectedPositions(['all'])
            }
        },
        [selectedPositions, setSelectedPositions]
    )

    return (
        <>
            <div className='px-32px'>
                <div>
                    <div className='row'>
                        <div className='pb-18px'>
                            <CustomAutoCompleteDropdown
                                display='comma'
                                label={'Offices'}
                                placeholder={'Select Offices'}
                                options={officeList}
                                selectedOptions={selectedOffices}
                                onChange={onOfficeSelect}
                                errorMessage={arenaEventErrorData?.offices}
                            />
                        </div>

                        <div className=''>
                            <CustomAutoCompleteDropdown
                                display='comma'
                                label={'Positions'}
                                placeholder={'Select Positions'}
                                options={positionList}
                                selectedOptions={selectedPositions}
                                onChange={onPositionSelect}
                                errorMessage={arenaEventErrorData?.positions}
                            />
                        </div>
                    </div>
                    <span className='text-sm-regular text-sqError500'>
                        {eventData?.users?.length == 0 ? '1 There must be user : Ladder' : ''}
                    </span>
                </div>
            </div>
            {viewParticipants && (
                <>
                    <CustomModal2
                        show={viewParticipants}
                        onHide={() => setViewParticipants(false)}
                        title={'View Participants'}
                        backButton={true}
                        maxWidth={500}
                        showHeader={false}
                        showCloseButton={false}
                        headerBorder={false}
                    >
                        <div
                            className={`d-flex justify-content-between align-items-center mb-32px`}
                        >
                            <div>
                                <KTSVG
                                    path='/media/icons/duotune/art/back-square.svg'
                                    svgClassName='h-25px w-25px cursor-pointer'
                                    onClick={() => setViewParticipants(false)}
                                />
                            </div>
                            <div className='text-lg-bold text-sqGray900'>
                                View Participants ({eventData?.users?.length ?? 0})
                            </div>

                            <KTSVG
                                path='/media/icons/duotune/art/gray-close-circle.svg'
                                svgClassName='w-30px h-30px cursor-pointer'
                                onClick={() => setViewParticipants(false)}
                            />
                        </div>
                        <div className='px-8'>
                            <div className='mb-18px row'>
                                <div className='col-lg-6'>
                                    <CustomAutoCompleteDropdown
                                        label={'Offices'}
                                        options={officeList}
                                        selectedOptions={selectedOffices}
                                        onChange={onOfficeSelect}
                                        display='comma'
                                        errorMessage={''}
                                    />
                                </div>
                                <div className='col-lg-6'>
                                    <CustomAutoCompleteDropdown
                                        label={'Positions'}
                                        options={positionList}
                                        display='comma'
                                        selectedOptions={selectedPositions}
                                        onChange={onPositionSelect}
                                        errorMessage={''}
                                    />
                                </div>
                            </div>
                            <div style={{height: 320}}>
                                <Show>
                                    <Show.When isVisible={getUserBasedOnOfficeAndPositionLoading}>
                                        <CustomLoader visible />
                                    </Show.When>
                                    <Show.Else>
                                        <div>
                                            <CustomInputChipOpen
                                                placeholder={'Search by name'}
                                                optionList={foundedUsers ?? []}
                                                searchFields={['user_name']}
                                                onOptionPress={onUserOptionPress}
                                                selectedList={eventData?.users}
                                                displayKey='user_name'
                                                valueKey='user_id'
                                            />
                                        </div>
                                    </Show.Else>
                                </Show>
                            </div>
                        </div>
                    </CustomModal2>
                </>
            )}
        </>
    )
}

export default AddParticipants
