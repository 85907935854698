import React, {useEffect, useMemo, useState} from 'react'
import CustomButton2, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../../../customComponents/customButtton/CustomButton2'
import CustomDropdown from '../../../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import images from '../../../../../../assets/images'
import {KTSVG} from '../../../../../../_metronic/helpers'
import CustomBadge from '../../../../../../customComponents/customBadge/CustomBadge'
import {
    ARENA_ASSIGN_MATCHES,
    ARENA_EVENT_TYPES,
    ARENA_NEW_EVENT_MODE,
} from '../../../../../../constants/constants'
import {ARENA_EVENT_KEYS} from './AddArenaEvent'
import Show from '../../../../../../customComponents/show/Show'
import CartoonText from '../../../../../../customComponents/cartoonText/CartoonText'
import _ from 'lodash'

const AssignMatches = ({
    eventData,
    updateEventData,
    setAssignMatcheUser,
    updateMultipleEventData,
}) => {
    const [activeGroup, setActiveGroup] = useState('Group A')
    const [sections, setSections] = useState([{}])
    const [addNewGroup, setAddNewGroup] = useState(false)
    const [userSelected, setUserSelected] = useState({})
    const [userSelectedData, setUserSelectedData] = useState({})
    const [menualData, setMenualData] = useState([])
    const [userSelectionData, setUserSelectionData] = useState([])
    const [battleUserData] = useState(_.shuffle(eventData?.[ARENA_EVENT_KEYS.users]))

    const addNewSection = () => {
        setSections([...sections, {}])
    }
    const addNewSectionBattle = () => {
        setAddNewGroup(true)
    }
    // let battleUserData

    useEffect(() => {
        if (eventData?.event_type === ARENA_EVENT_TYPES.battle) {
            const groupData = []
            // let usersData = eventData?.[ARENA_EVENT_KEYS.users]

            for (let i = 0; i < battleUserData?.length; i += 2) {
                let match = {
                    user1_name: battleUserData?.[i]?.user_name || '',
                    user1_id: battleUserData?.[i]?.user_id || '',
                    user1_image: battleUserData?.[i]?.user_image || '',
                }

                if (battleUserData?.[i + 1]?.user_name) {
                    match = {
                        ...match,
                        user2_name: battleUserData?.[i + 1]?.user_name || '',
                        user2_id: battleUserData?.[i + 1]?.user_id || '',
                        user2_image: battleUserData?.[i + 1]?.user_image || '',
                    }
                }

                groupData.push(match)
            }

            setAssignMatcheUser(groupData)
            updateMultipleEventData({
                [ARENA_EVENT_KEYS.game_rounds]: groupData,
            })
        }
    }, [eventData?.[ARENA_EVENT_KEYS.users]])

    const usersDataShow = useMemo(() => {
        const shuffledData = _.shuffle(eventData?.[ARENA_EVENT_KEYS.users])
        const midPoint = Math.ceil(shuffledData.length / 2)

        return {
            groupA: shuffledData.slice(0, midPoint),
            groupB: shuffledData.slice(midPoint),
        }
    }, [eventData, ARENA_EVENT_KEYS])

    const {groupA, groupB} = usersDataShow

    useEffect(() => {
        if (eventData?.event_type == ARENA_EVENT_TYPES.bracket) {
            const groupData = [
                {
                    group_name: 'GroupA',
                    users: [],
                },
                {
                    group_name: 'GroupB',
                    users: [],
                },
            ]

            let usersData = _.shuffle(eventData?.[ARENA_EVENT_KEYS.users])

            const totalMatches = Math.ceil(usersData.length / 2)
            const halfMatches = Math.ceil(totalMatches / 2)

            for (let i = 0; i < usersData?.length; i += 2) {
                let match = {
                    user1_name: usersData?.[i]?.user_name || '',
                    user1_id: usersData?.[i]?.user_id || '',
                    user1_image: usersData?.[i]?.user_image || '',
                }

                if (usersData?.[i + 1]?.user_name) {
                    match = {
                        ...match,
                        user2_name: usersData?.[i + 1]?.user_name || '',
                        user2_id: usersData?.[i + 1]?.user_id || '',
                        user2_image: usersData?.[i + 1]?.user_image || '',
                    }
                }

                if (groupData[0].users.length < halfMatches) {
                    groupData[0].users.push(match)
                } else {
                    groupData[1].users.push(match)
                }
            }

            setAssignMatcheUser(groupData)
        }
    }, [eventData?.users])

    const handleUserSelected = (e, person) => {
        const selectedUserName = e.target.value

        // Find the selected user based on user_name
        const personData = battleUserData.find((item) => item.user_name === selectedUserName)

        setUserSelected((prevSelected) => ({
            ...prevSelected,
            [person]: selectedUserName,
        }))

        setUserSelectedData((prevSelectedData) => ({
            ...prevSelectedData,
            [person]: personData,
        }))

        updateUserSelectionData()
    }

    const saveData = () => {
        if (userSelectedData.person1 && userSelectedData.person2) {
            setMenualData((prevMenualData) => [
                ...prevMenualData,
                userSelectedData.person1,
                userSelectedData.person2,
            ])

            setUserSelectedData({})
            setUserSelected({})
            setAddNewGroup(false)
        }
    }

    const updateUserSelectionData = () => {
        const userSelectedIds = menualData?.map((item) => item?.user_id) || []
        const excludedIds = [
            ...userSelectedIds,
            userSelectedData?.person1?.user_id,
            userSelectedData?.person2?.user_id,
        ].filter(Boolean)

        const filteredData =
            battleUserData?.filter((item) => !excludedIds.includes(item?.user_id)) || []
        setUserSelectionData(filteredData)
    }

    useEffect(() => {
        setUserSelectionData(battleUserData)
        updateUserSelectionData()
    }, [userSelectedData, menualData])

    return (
        <>
            <div className='px-32px'>
                <div className=''>
                    <div className='text-md-semibold'>Choose How you want to create matches.</div>

                    <div className='text-sm-medium text-sqGray600 mb-6px'>
                        Either assign players to matches manually or let us do the work for you by
                        choosing random.
                    </div>
                    <div className='row mb-24px'>
                        <div className='col-sm'>
                            <CustomDropdown
                                label={''}
                                // required
                                options={ARENA_ASSIGN_MATCHES}
                                showClear={false}
                                name={ARENA_EVENT_KEYS.assign_player_mode}
                                value={eventData?.[ARENA_EVENT_KEYS.assign_player_mode]}
                                onChange={updateEventData}
                                // errorMessage={
                                //     arenaEventErrorData?.[ARENA_EVENT_KEYS.competition_duration]
                                // }
                            />
                        </div>
                    </div>
                    <Show>
                        <Show.When
                            isVisible={[ARENA_EVENT_TYPES.bracket].includes(
                                eventData?.[ARENA_EVENT_KEYS.event_type]
                            )}
                        >
                            <>
                                <div className='d-flex mb-24px gap-40px'>
                                    <span
                                        className={
                                            activeGroup === 'Group A'
                                                ? 'text-sqPrimary600 text-md-bold text-decoration-underline'
                                                : 'text-md-medium text-sqGray500 cursor-pointer'
                                        }
                                        onClick={() => setActiveGroup('Group A')}
                                    >
                                        Group A
                                    </span>
                                    <span
                                        className={
                                            activeGroup === 'GroupB'
                                                ? 'text-sqPrimary600 text-md-bold text-decoration-underline'
                                                : 'text-md-medium text-sqGray500 cursor-pointer'
                                        }
                                        onClick={() => setActiveGroup('GroupB')}
                                    >
                                        Group B
                                    </span>
                                </div>
                                <Show>
                                    <Show.When
                                        isVisible={
                                            eventData?.[ARENA_EVENT_KEYS.assign_player_mode] ==
                                            'Manual'
                                        }
                                    >
                                        <BracketGroupAMenualSection
                                            battleUserData={battleUserData}
                                            userSelected={userSelected}
                                            handleUserSelected={handleUserSelected}
                                            userSelectionData={userSelectionData}
                                            addNewGroup={addNewGroup}
                                        />
                                        <div>Demoooooo</div>
                                        <div className='d-flex mt-6 gap-2'>
                                            <div
                                                className='cursor-pointer'
                                                onClick={addNewSectionBattle}
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/arena/plus-square.svg'
                                                    className='mb-2'
                                                    svgClassName='w-20px h-20px'
                                                />
                                                <span className='text-sm-semibold text-sqPrimary600'>
                                                    Add New
                                                </span>
                                            </div>
                                            <Show>
                                                <Show.When isVisible={addNewGroup}>
                                                    <div
                                                        className='ms-auto cursor-pointer'
                                                        onClick={saveData}
                                                    >
                                                        <span className='text-sm-semibold text-sqPrimary600'>
                                                            Save
                                                        </span>
                                                    </div>
                                                </Show.When>
                                            </Show>
                                        </div>
                                    </Show.When>
                                    <Show.Else>
                                        {activeGroup === 'Group A' && (
                                            <>
                                                <CompetitionSection
                                                    item={groupA}
                                                    groupName='Group A'
                                                />
                                            </>
                                        )}
                                        {activeGroup === 'GroupB' && (
                                            <>
                                                <CompetitionSection
                                                    item={groupB}
                                                    groupName='Group B'
                                                />
                                            </>
                                        )}
                                    </Show.Else>
                                </Show>
                            </>
                        </Show.When>
                        <Show.Else>
                            <Show>
                                <Show.When
                                    isVisible={
                                        eventData?.[ARENA_EVENT_KEYS.assign_player_mode] == 'Manual'
                                    }
                                >
                                    {menualData && menualData?.length > 0 && (
                                        <BattleRandomSection item={menualData} />
                                    )}
                                    <BattleMenualSection
                                        battleUserData={battleUserData}
                                        userSelected={userSelected}
                                        handleUserSelected={handleUserSelected}
                                        userSelectionData={userSelectionData}
                                        addNewGroup={addNewGroup}
                                    />
                                </Show.When>
                                <Show.Else>
                                    <BattleRandomSection item={battleUserData} />
                                    {/* <CompetitionSection item={battleUserData} /> */}
                                </Show.Else>
                            </Show>
                            <Show>
                                <Show.When
                                    isVisible={
                                        eventData?.[ARENA_EVENT_KEYS.assign_player_mode] == 'Manual'
                                    }
                                >
                                    <div className='d-flex mt-6 gap-2'>
                                        <div
                                            className='cursor-pointer'
                                            onClick={addNewSectionBattle}
                                        >
                                            <KTSVG
                                                path='/media/icons/duotune/arena/plus-square.svg'
                                                className='mb-2'
                                                svgClassName='w-20px h-20px'
                                            />
                                            <span className='text-sm-semibold text-sqPrimary600'>
                                                Add New
                                            </span>
                                        </div>
                                        <Show>
                                            <Show.When isVisible={addNewGroup}>
                                                <div
                                                    className='ms-auto cursor-pointer'
                                                    onClick={saveData}
                                                >
                                                    <span className='text-sm-semibold text-sqPrimary600'>
                                                        Save
                                                    </span>
                                                </div>
                                            </Show.When>
                                        </Show>
                                    </div>
                                </Show.When>
                            </Show>
                        </Show.Else>
                    </Show>
                </div>
            </div>
        </>
    )
}

export default AssignMatches

const CompetitionSection = ({item, images}) => (
    <>
        {item?.map((user, index) => {
            if (index % 2 === 0 && index === item.length - 1) {
                return (
                    <div
                        key={index}
                        className='d-flex align-items-center justify-content-center border p-4 mb-10'
                        style={{borderRadius: '8px', height: '65px'}}
                    >
                        {/* Single Last User */}
                        <div className='d-flex align-items-center justify-content-center flex-row pb-3 mb-5 mt-8'>
                            <label htmlFor='upload-image' style={{cursor: 'pointer'}}>
                                <KTSVG
                                    path='/media/icons/duotune/arena/user1.svg'
                                    svgClassName='h-40px w-40px'
                                />
                            </label>
                            <div className='d-flex flex-column ps-3'>
                                <span className='mb-1 text-sm-semibold text-sqGray900'>
                                    {item[index]?.user_name}
                                </span>
                                <div className='d-flex gap-3'>
                                    <CustomBadge
                                        textColor='sqGray700'
                                        fontWeight={700}
                                        fontSize={12}
                                        px={3}
                                        py={2}
                                        backgroundColor={'sqGray100'}
                                        opacity={false}
                                        rounded={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else if (index % 2 === 0) {
                // Normal pairing of two users
                return (
                    <div
                        key={index}
                        className='d-flex align-items-center justify-content-between border p-3 mb-4'
                        style={{borderRadius: '8px', height: 'auto'}}
                    >
                        {/* First User */}
                        <div className='d-flex align-items-center flex-row'>
                            <label htmlFor='upload-image' style={{cursor: 'pointer'}}>
                                <KTSVG
                                    path='/media/icons/duotune/arena/user1.svg'
                                    svgClassName='h-40px w-40px'
                                />
                            </label>
                            <div className='d-flex flex-column ps-3px'>
                                <span className='mb-1 text-sm-semibold text-sqGray900 text-truncate w-100px'>
                                    {item[index]?.user_name}
                                </span>
                                <div className='d-flex gap-3'>
                                    <CustomBadge
                                        label={'Setter'}
                                        textColor='sqGray700'
                                        fontWeight={700}
                                        fontSize={12}
                                        px={3}
                                        py={2}
                                        backgroundColor={'sqGray100'}
                                        opacity={false}
                                        rounded={true}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* VS text centered */}
                        <div className='d-flex align-items-center justify-content-center'>
                            <CartoonText fontSize={'32px'} isStroke={false} color={'black'}>
                                VS
                            </CartoonText>
                        </div>

                        {/* Second User */}
                        <div className='d-flex align-items-center flex-row justify-content-end'>
                            <label htmlFor='upload-image' style={{cursor: 'pointer'}}>
                                <KTSVG
                                    path='/media/icons/duotune/arena/user1.svg'
                                    svgClassName='h-40px w-40px'
                                />
                            </label>
                            <div className='d-flex flex-column ps-3'>
                                <span className='mb-1 text-sm-semibold text-sqGray900 text-truncate w-100px'>
                                    {item[index + 1]?.user_name}
                                </span>
                                <div className='d-flex gap-3'>
                                    <CustomBadge
                                        label={'Setter'}
                                        textColor='sqGray700'
                                        fontWeight={700}
                                        fontSize={12}
                                        px={3}
                                        py={2}
                                        backgroundColor={'sqGray100'}
                                        opacity={false}
                                        rounded={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return null
            }
        })}
    </>
)

const BracketGroupAMenualSection = ({
    battleUserData,
    handleUserSelected,
    userSelected,
    userSelectionData,
    addNewGroup,
}) => (
    <>
        {addNewGroup && (
            <div
                className='d-flex align-items-center justify-content-between border px-12px mb-24px'
                style={{borderRadius: '8px', height: '66px'}}
            >
                <div className='d-flex align-items-center flex-row pb-3 mb-5 mt-8'>
                    {/* <label htmlFor='upload-image' style={{cursor: 'pointer'}}>
                <KTSVG path='/media/icons/duotune/arena/user1.svg' svgClassName='h-40px w-40px' />
            </label> */}
                    <div className='d-flex flex-column ps-3'>
                        <CustomDropdown
                            required
                            id='Manage_Leads_Home_Location_Input'
                            options={userSelected?.person1 ? battleUserData : userSelectionData}
                            name={ARENA_EVENT_KEYS.user_name}
                            value={userSelected?.person1}
                            onChange={(e) => handleUserSelected(e, 'person1')}
                            valueKey='user_name'
                            displayKey='user_name'
                        />
                    </div>
                </div>

                <div
                    className='px-4 text-center'
                    style={{
                        fontFamily: 'Digitalt',
                        fontSize: '32px',
                        fontWeight: '500',
                        color: '#393939',
                    }}
                >
                    VS
                </div>

                <div className='d-flex align-items-center flex-row pb-3 mb-5 mt-8 justify-content-end'>
                    {/* <label htmlFor='upload-image' style={{cursor: 'pointer'}}>
                <KTSVG path='/media/icons/duotune/arena/user1.svg' svgClassName='h-40px w-40px' />
            </label> */}
                    <div className='d-flex flex-column ps-3'>
                        <CustomDropdown
                            required
                            id='Manage_Leads_Home_Location_Input'
                            options={userSelected?.person2 ? battleUserData : userSelectionData}
                            name={ARENA_EVENT_KEYS.user_name}
                            value={userSelected?.person2}
                            // onChange={updateEventData}
                            onChange={(e) => handleUserSelected(e, 'person2')}
                            valueKey='user_name'
                            displayKey='user_name'
                        />
                    </div>
                </div>
            </div>
        )}
    </>
)
const BattleMenualSection = ({
    battleUserData,
    handleUserSelected,
    userSelected,
    userSelectionData,
    addNewGroup,
}) => (
    <>
        {addNewGroup && (
            <div
                className='d-flex align-items-center justify-content-between border px-12px mb-24px'
                style={{borderRadius: '8px', height: '66px'}}
            >
                <div className='d-flex align-items-center flex-row pb-3 mb-5 mt-8'>
                    {/* <label htmlFor='upload-image' style={{cursor: 'pointer'}}>
                <KTSVG path='/media/icons/duotune/arena/user1.svg' svgClassName='h-40px w-40px' />
            </label> */}
                    <div className='d-flex flex-column ps-3'>
                        <CustomDropdown
                            required
                            id='Manage_Leads_Home_Location_Input'
                            options={userSelected?.person1 ? battleUserData : userSelectionData}
                            name={ARENA_EVENT_KEYS.user_name}
                            value={userSelected?.person1}
                            onChange={(e) => handleUserSelected(e, 'person1')}
                            valueKey='user_name'
                            displayKey='user_name'
                        />
                    </div>
                </div>

                <div
                    className='px-4 text-center'
                    style={{
                        fontFamily: 'Digitalt',
                        fontSize: '32px',
                        fontWeight: '500',
                        color: '#393939',
                    }}
                >
                    VS
                </div>

                <div className='d-flex align-items-center flex-row pb-3 mb-5 mt-8 justify-content-end'>
                    {/* <label htmlFor='upload-image' style={{cursor: 'pointer'}}>
                <KTSVG path='/media/icons/duotune/arena/user1.svg' svgClassName='h-40px w-40px' />
            </label> */}
                    <div className='d-flex flex-column ps-3'>
                        <CustomDropdown
                            required
                            id='Manage_Leads_Home_Location_Input'
                            options={userSelected?.person2 ? battleUserData : userSelectionData}
                            name={ARENA_EVENT_KEYS.user_name}
                            value={userSelected?.person2}
                            // onChange={updateEventData}
                            onChange={(e) => handleUserSelected(e, 'person2')}
                            valueKey='user_name'
                            displayKey='user_name'
                        />
                    </div>
                </div>
            </div>
        )}
    </>
)

const BattleRandomSection = ({item}) => (
    <>
        {item?.map((user, index) => {
            if (index % 2 === 0 && index === item.length - 1) {
                return (
                    <div
                        key={index}
                        className='d-flex align-items-center justify-content-center border p-4 mb-10'
                        style={{borderRadius: '8px', height: '65px'}}
                    >
                        {/* Single Last User */}
                        <div className='d-flex align-items-center justify-content-center flex-row pb-3 mb-5 mt-8'>
                            <label htmlFor='upload-image' style={{cursor: 'pointer'}}>
                                <KTSVG
                                    path='/media/icons/duotune/arena/user1.svg'
                                    svgClassName='h-40px w-40px'
                                />
                            </label>
                            <div className='d-flex flex-column ps-3'>
                                <span className='mb-1 text-sm-semibold text-sqGray900'>
                                    {item[index]?.user_name}
                                </span>
                                <div className='d-flex gap-3'>
                                    <CustomBadge
                                        label={'Setter'}
                                        textColor='sqGray700'
                                        fontWeight={700}
                                        fontSize={12}
                                        px={3}
                                        py={2}
                                        backgroundColor={'sqGray100'}
                                        opacity={false}
                                        rounded={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else if (index % 2 === 0) {
                // Normal pairing of two users
                return (
                    <div
                        key={index}
                        className='d-flex align-items-center justify-content-between border p-3 mb-4'
                        style={{borderRadius: '8px', height: 'auto'}}
                    >
                        {/* First User */}
                        <div className='d-flex align-items-center flex-row'>
                            <label htmlFor='upload-image' style={{cursor: 'pointer'}}>
                                <KTSVG
                                    path='/media/icons/duotune/arena/user1.svg'
                                    svgClassName='h-40px w-40px'
                                />
                            </label>
                            <div className='d-flex flex-column ps-3px'>
                                <span className='mb-1 text-sm-semibold text-sqGray900 text-truncate w-100px'>
                                    {item[index]?.user_name}
                                </span>
                                <div className='d-flex gap-3'>
                                    <CustomBadge
                                        label={'Setter'}
                                        textColor='sqGray700'
                                        fontWeight={700}
                                        fontSize={12}
                                        px={3}
                                        py={2}
                                        backgroundColor={'sqGray100'}
                                        opacity={false}
                                        rounded={true}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* VS text centered */}
                        <div className='d-flex align-items-center justify-content-center'>
                            <CartoonText fontSize={'32px'} isStroke={false} color={'black'}>
                                VS
                            </CartoonText>
                        </div>

                        {/* Second User */}
                        <div className='d-flex align-items-center flex-row justify-content-end'>
                            <label htmlFor='upload-image' style={{cursor: 'pointer'}}>
                                <KTSVG
                                    path='/media/icons/duotune/arena/user1.svg'
                                    svgClassName='h-40px w-40px'
                                />
                            </label>
                            <div className='d-flex flex-column ps-3'>
                                <span className='mb-1 text-sm-semibold text-sqGray900 text-truncate w-100px'>
                                    {item[index + 1]?.user_name}
                                </span>
                                <div className='d-flex gap-3'>
                                    <CustomBadge
                                        label={'Setter'}
                                        textColor='sqGray700'
                                        fontWeight={700}
                                        fontSize={12}
                                        px={3}
                                        py={2}
                                        backgroundColor={'sqGray100'}
                                        opacity={false}
                                        rounded={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return null
            }
        })}
    </>
)
