import React, {useCallback, useEffect, useMemo} from 'react'
import CustomInput, {
    CommonLabel,
    INPUT_TYPE,
} from '../../../../../../customComponents/customInputs/customInput/CustomInput'
import CustomCheckbox from '../../../../../../customComponents/customCheckbox/CustomCheckbox'
import {KTSVG} from '../../../../../../_metronic/helpers'
import CustomDropdown from '../../../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import {ARENA_EVENT_KEYS} from './AddArenaEvent'
import _ from 'lodash'
import {ARENA_EVENT_TYPES, ARENA_REWARD_TYPES} from '../../../../../../constants/constants'
import Show from '../../../../../../customComponents/show/Show'
import {getNumberOrdinal} from '../../../../../../helpers/CommonHelpers'

const SetGameRewards = ({
    eventData,
    updateEventData,
    arenaEventErrorData,
    updateMultipleEventData,
    eventMetaData,
}) => {
    useEffect(() => {
        if ([ARENA_EVENT_TYPES.ladder].includes(eventData?.[ARENA_EVENT_KEYS.event_type])) {
            addReward()
        }
    }, [])

    // Function to handle adding a new milestone
    const addReward = useCallback(() => {
        let data = _.cloneDeep(eventData?.[ARENA_EVENT_KEYS.rewards_data] ?? [])
        let obj = {reward_type: 'Cash', reward: null, hide_reward: false}
        if (eventData?.[ARENA_EVENT_KEYS.event_type] == ARENA_EVENT_TYPES.ladder) {
            obj.points_required = ''
        }
        data.push(obj)
        updateMultipleEventData({
            [ARENA_EVENT_KEYS.rewards_data]: data,
        })
    }, [eventData, updateMultipleEventData])

    const updateRewardData = (index, e) => {
        let data = _.cloneDeep(eventData?.[ARENA_EVENT_KEYS.rewards_data])
        data[index][e?.target?.name] = e?.target?.value
        if (e?.target?.name == ARENA_EVENT_KEYS.reward_type) {
            data[index][ARENA_EVENT_KEYS.reward] = ''
        }
        updateMultipleEventData({
            [ARENA_EVENT_KEYS.rewards_data]: data,
        })
    }

    const updateBattledData = (index, e) => {
        const {name, value} = e.target
        let updatedEventData = {...eventData}

        // Update the specific reward data based on the field name
        updatedEventData[name] = value

        // Update the state with the new data
        updateMultipleEventData({[ARENA_EVENT_KEYS.rewards_data]: updatedEventData})
    }

    return (
        <div className='px-32px'>
            <div className=''>
                <div className='text-md-semibold text-sqGray900 mb-32px'>Set No. of Milestones</div>

                <Show>
                    <Show.When
                        isVisible={[
                            ARENA_EVENT_TYPES.ladder,
                            ARENA_EVENT_TYPES.classicRoulette,
                            ARENA_EVENT_TYPES.russianRoulette,
                        ].includes(eventData?.[ARENA_EVENT_KEYS.event_type])}
                    >
                        <div className=''>
                            {eventData?.[ARENA_EVENT_KEYS?.rewards_data]?.map((reward, index) => {
                                let roundData = eventData?.[ARENA_EVENT_KEYS?.game_rounds]?.[index]

                                return (
                                    <>
                                        <div key={reward?.id} className='mb-32px'>
                                            <div className='text-sm-bold text-sqGray900 mb-18px'>
                                                <Show>
                                                    <Show.When
                                                        isVisible={[
                                                            ARENA_EVENT_TYPES.ladder,
                                                        ].includes(
                                                            eventData?.[ARENA_EVENT_KEYS.event_type]
                                                        )}
                                                    >
                                                        Milestone {index + 1}
                                                    </Show.When>
                                                    <Show.When
                                                        isVisible={[
                                                            ARENA_EVENT_TYPES.champion,
                                                        ].includes(
                                                            eventData?.[ARENA_EVENT_KEYS.event_type]
                                                        )}
                                                    >
                                                        <div className='text-md-medium mb-18px'>
                                                            <img
                                                                alt='sequifi'
                                                                src={
                                                                    eventMetaData?.[
                                                                        `place_${
                                                                            index > 3
                                                                                ? 4
                                                                                : index + 1
                                                                        }_icon`
                                                                    ]
                                                                }
                                                                className='w-24px h-24px'
                                                            />
                                                            {index + 1}
                                                            {getNumberOrdinal(index + 1)} Place
                                                        </div>
                                                    </Show.When>
                                                    {/* -------------------- Survivor -------------------- */}
                                                    <Show.When
                                                        isVisible={[
                                                            ARENA_EVENT_TYPES.survivor,
                                                            ARENA_EVENT_TYPES.performance,
                                                            ARENA_EVENT_TYPES.classicRoulette,
                                                            ARENA_EVENT_TYPES.russianRoulette,
                                                        ].includes(
                                                            eventData?.[ARENA_EVENT_KEYS.event_type]
                                                        )}
                                                    >
                                                        Round {index + 1} -{' '}
                                                        {roundData?.competition_no_of_days} days
                                                    </Show.When>
                                                    {/* -------------------- Battle -------------------- */}
                                                    <Show.When
                                                        isVisible={[
                                                            ARENA_EVENT_TYPES.battle,
                                                        ].includes(
                                                            eventData?.[ARENA_EVENT_KEYS.event_type]
                                                        )}
                                                    >
                                                        Set Reward List
                                                    </Show.When>
                                                    <Show.Else></Show.Else>
                                                </Show>
                                            </div>
                                            <Show>
                                                <Show.When
                                                    isVisible={[ARENA_EVENT_TYPES.ladder].includes(
                                                        eventData?.[ARENA_EVENT_KEYS.event_type]
                                                    )}
                                                >
                                                    <>
                                                        <div className='row mb-18px gap-2'>
                                                            <div className='col-sm'>
                                                                <PointsRequiredInput
                                                                    reward={reward}
                                                                    index={index}
                                                                    updateRewardData={
                                                                        updateRewardData
                                                                    }
                                                                    arenaEventErrorData={
                                                                        arenaEventErrorData
                                                                    }
                                                                />
                                                            </div>

                                                            <div className='col-sm'>
                                                                <RewardTypeInput
                                                                    reward={reward}
                                                                    index={index}
                                                                    updateRewardData={
                                                                        updateRewardData
                                                                    }
                                                                    arenaEventErrorData={
                                                                        arenaEventErrorData
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='mb-18px'>
                                                            <RewardInput
                                                                reward={reward}
                                                                index={index}
                                                                updateRewardData={updateRewardData}
                                                                arenaEventErrorData={
                                                                    arenaEventErrorData
                                                                }
                                                            />
                                                        </div>
                                                    </>
                                                </Show.When>
                                                <Show.Else>
                                                    <div className='row mb-18px gap-2'>
                                                        <div className='col-sm'>
                                                            <RewardTypeInput
                                                                reward={reward}
                                                                index={index}
                                                                updateRewardData={updateRewardData}
                                                                arenaEventErrorData={
                                                                    arenaEventErrorData
                                                                }
                                                            />
                                                        </div>
                                                        <div className='col-sm'>
                                                            <RewardInput
                                                                reward={reward}
                                                                index={index}
                                                                updateRewardData={updateRewardData}
                                                                arenaEventErrorData={
                                                                    arenaEventErrorData
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </Show.Else>
                                            </Show>

                                            <Show>
                                                <Show.When
                                                    isVisible={[
                                                        ARENA_EVENT_TYPES.ladder,
                                                        ARENA_EVENT_TYPES.classicRoulette,
                                                        ARENA_EVENT_TYPES.russianRoulette,
                                                    ].includes(
                                                        eventData?.[ARENA_EVENT_KEYS.event_type]
                                                    )}
                                                >
                                                    <div className='d-flex gap-2'>
                                                        <HideRewardCheckbox
                                                            reward={reward}
                                                            index={index}
                                                            updateRewardData={updateRewardData}
                                                            arenaEventErrorData={
                                                                arenaEventErrorData
                                                            }
                                                        />
                                                    </div>
                                                </Show.When>
                                            </Show>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </Show.When>
                    <Show.When
                        isVisible={[ARENA_EVENT_TYPES.battle].includes(
                            eventData?.[ARENA_EVENT_KEYS.event_type]
                        )}
                    >
                        <div className='row mb-18px gap-2'>
                            <div className='col-sm'>
                                <CustomDropdown
                                    required
                                    label={'Select Reward Type'}
                                    options={ARENA_REWARD_TYPES}
                                    showClear={false}
                                    name={ARENA_EVENT_KEYS.reward_type}
                                    value={eventData?.[ARENA_EVENT_KEYS.reward_type]}
                                    onChange={(e) => updateBattledData('', e)} // Pass the event to update
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.reward_type]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Reward'}
                                    placeholder={
                                        eventData?.[ARENA_EVENT_KEYS.reward_type] === 'Cash'
                                            ? '0'
                                            : 'iPhone'
                                    }
                                    prefixText={
                                        eventData?.[ARENA_EVENT_KEYS.reward_type] === 'Cash'
                                            ? '$'
                                            : ''
                                    }
                                    type={
                                        eventData?.[ARENA_EVENT_KEYS.reward_type] === 'Cash'
                                            ? INPUT_TYPE.number
                                            : INPUT_TYPE.text
                                    }
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.reward}
                                    value={eventData?.[ARENA_EVENT_KEYS.reward]}
                                    onChange={(e) => updateBattledData('', e)}
                                    errorMessage={arenaEventErrorData?.[ARENA_EVENT_KEYS.reward]}
                                />
                            </div>
                            <div>
                                <CustomCheckbox
                                    name={ARENA_EVENT_KEYS.hide_reward}
                                    checked={eventData?.[ARENA_EVENT_KEYS.hide_reward] || false}
                                    onChange={(e) => {
                                        updateBattledData('', {
                                            ...e,
                                            target: {
                                                ...e?.target,
                                                value: !eventData?.[ARENA_EVENT_KEYS.hide_reward], // Toggle hide reward
                                            },
                                        })
                                    }}
                                />
                            </div>
                            <div>
                                <CommonLabel className='' label='Hide rewards for players' />
                            </div>
                        </div>
                    </Show.When>
                </Show>
                <Show></Show>
                <Show>
                    <Show.When
                        isVisible={[ARENA_EVENT_TYPES.ladder, ARENA_EVENT_TYPES.champion].includes(
                            eventData?.event_type
                        )}
                    >
                        <div className='d-flex gap-2 cursor-pointer' onClick={addReward}>
                            <div>
                                <KTSVG
                                    path='/media/icons/duotune/arena/plus-square.svg'
                                    className=' mb-2'
                                    svgClassName='w-20px h-20px'
                                />
                            </div>
                            <div>
                                <span className='text-sm-semibold text-sqPrimary600'>
                                    <Show>
                                        <Show.When
                                            isVisible={[ARENA_EVENT_TYPES.ladder].includes(
                                                eventData?.event_type
                                            )}
                                        >
                                            Add another Milestone
                                        </Show.When>
                                        <Show.Else>Add Reward</Show.Else>
                                    </Show>
                                </span>
                            </div>
                        </div>
                    </Show.When>
                </Show>
            </div>
        </div>
    )
}

export default SetGameRewards

const PointsRequiredInput = ({index, reward, arenaEventErrorData, updateRewardData}) => (
    <CustomInput
        required
        label={'Points Required '}
        placeholder='0'
        type={INPUT_TYPE.number}
        restrictNegativeNum
        name={ARENA_EVENT_KEYS.points_required}
        value={reward?.[ARENA_EVENT_KEYS.points_required]}
        onChange={(e) => updateRewardData(index, e)}
        errorMessage={
            arenaEventErrorData?.[ARENA_EVENT_KEYS.rewards_data]?.[index]?.[
                ARENA_EVENT_KEYS.points_required
            ]
        }
    />
)

const RewardTypeInput = ({index, reward, arenaEventErrorData, updateRewardData}) => (
    <CustomDropdown
        required
        label={'Select Reward Type'}
        options={ARENA_REWARD_TYPES}
        showClear={false}
        name={ARENA_EVENT_KEYS.reward_type}
        value={reward?.[ARENA_EVENT_KEYS.reward_type]}
        onChange={(e) => updateRewardData(index, e)}
        errorMessage={
            arenaEventErrorData?.[ARENA_EVENT_KEYS.rewards_data]?.[index]?.[
                ARENA_EVENT_KEYS.reward_type
            ]
        }
    />
)

const RewardInput = ({index, reward, arenaEventErrorData, updateRewardData}) => (
    <CustomInput
        required
        label={'Reward'}
        placeholder={reward?.[ARENA_EVENT_KEYS.reward_type] == 'Cash' ? '0' : 'iPhone'}
        prefixText={reward?.[ARENA_EVENT_KEYS.reward_type] == 'Cash' ? '$' : ''}
        type={
            reward?.[ARENA_EVENT_KEYS.reward_type] == 'Cash' ? INPUT_TYPE.number : INPUT_TYPE.text
        }
        restrictNegativeNum
        name={ARENA_EVENT_KEYS.reward}
        value={reward?.[ARENA_EVENT_KEYS.reward]}
        onChange={(e) => updateRewardData(index, e)}
        errorMessage={
            arenaEventErrorData?.[ARENA_EVENT_KEYS.rewards_data]?.[index]?.[ARENA_EVENT_KEYS.reward]
        }
    />
)

const HideRewardCheckbox = ({index, reward, arenaEventErrorData, updateRewardData}) => (
    <>
        <div>
            <CustomCheckbox
                name={ARENA_EVENT_KEYS.hide_reward}
                checked={reward?.[ARENA_EVENT_KEYS.hide_reward] ? true : false}
                onChange={(e) => {
                    updateRewardData(index, {
                        ...e,
                        target: {
                            ...e?.target,
                            value: !reward?.[ARENA_EVENT_KEYS.hide_reward],
                        },
                    })
                }}
            />
        </div>
        <div>
            <CommonLabel className='' label='Hide rewards for players' />
        </div>
    </>
)
