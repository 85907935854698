import CustomInput, {
    INPUT_TYPE,
} from '../../../../../../customComponents/customInputs/customInput/CustomInput'
import CustomDropdown from '../../../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import CustomDatePicker from '../../../../../../customComponents/customInputs/customDatePicker/CustomDatePicker'
import _ from 'lodash'
import {ARENA_EVENT_KEYS} from './AddArenaEvent'
import {
    ARENA_COMPETITION_CUTOFF_WIN_CRITERIA,
    ARENA_COMPETITION_DURATION,
    ARENA_EVENT_TYPES,
    ARENA_WEEK_DAYS,
    getValidDate,
} from '../../../../../../constants/constants'
import CustomAutoCompleteDropdown from '../../../../../../customComponents/customInputs/customAutoCompleteDropdown/CustomAutoCompleteDropdown'
import {useCallback, useEffect} from 'react'
import Show from '../../../../../../customComponents/show/Show'
import {customMoment} from '../../../../../../customComponents/customMoment/customMoment'
import moment from 'moment'
import {KTSVG} from '../../../../../../_metronic/helpers'

const SetGameRules = ({
    eventData,
    updateEventData,
    arenaEventErrorData,
    updateMultipleEventData,
    assignMatcheUser,
}) => {
    useEffect(() => {
        if ([ARENA_EVENT_TYPES.ladder].includes(eventData?.[ARENA_EVENT_KEYS.event_type])) {
            let start_date = getValidDate(
                eventData?.[ARENA_EVENT_KEYS.competition_start_date],
                'YYYY-MM-DD'
            )
            let end_date = getValidDate(
                eventData?.[ARENA_EVENT_KEYS.competition_end_date],
                'YYYY-MM-DD'
            )
            let duration = eventData?.[ARENA_EVENT_KEYS.competition_duration]

            if (duration === 'set_by_exact_date') {
                let no_of_days =
                    start_date && end_date ? moment(end_date).diff(start_date, 'd') + 1 : ''

                updateMultipleEventData({
                    [ARENA_EVENT_KEYS.competition_start_date]: start_date,
                    [ARENA_EVENT_KEYS.competition_end_date]: end_date,
                    [ARENA_EVENT_KEYS.competition_no_of_days]: no_of_days,
                })
            } else {
                // Handle other duration cases
                let competition_days = eventData?.[ARENA_EVENT_KEYS.competition_days] || []
                let no_of_days = eventData?.[ARENA_EVENT_KEYS.competition_no_of_days] || ''

                let validDaysOfWeek = new Set(competition_days) // [1, 2, 3, 4, 6] -> Monday, Tuesday, Wednesday, Thursday, Saturday

                let currentDate = moment(start_date)
                let countedDays = 0

                while (countedDays < no_of_days) {
                    let dayOfWeek = currentDate.isoWeekday()

                    if (validDaysOfWeek.has(dayOfWeek)) {
                        countedDays++
                    }

                    if (countedDays < no_of_days) {
                        currentDate.add(1, 'day')
                    }
                }

                let end_date = currentDate.format('YYYY-MM-DD')

                updateMultipleEventData({
                    [ARENA_EVENT_KEYS.competition_start_date]: start_date,
                    [ARENA_EVENT_KEYS.competition_end_date]: end_date,
                    [ARENA_EVENT_KEYS.competition_no_of_days]: no_of_days,
                })
            }
        }
    }, [
        eventData?.[ARENA_EVENT_KEYS.competition_duration],
        eventData?.[ARENA_EVENT_KEYS.competition_start_date],
        eventData?.[ARENA_EVENT_KEYS.competition_end_date],
        eventData?.[ARENA_EVENT_KEYS.competition_no_of_days],
        eventData?.[ARENA_EVENT_KEYS.competition_days],
        eventData?.[ARENA_EVENT_KEYS.event_type],
    ])
    console.log('assignMatcheUser', assignMatcheUser)

    useEffect(() => {
        if (
            [
                ARENA_EVENT_TYPES.survivor,
                ARENA_EVENT_TYPES.performance,
                ARENA_EVENT_TYPES.classicRoulette,
                ARENA_EVENT_TYPES.russianRoulette,
            ].includes(eventData?.[ARENA_EVENT_KEYS.event_type])
        ) {
            addGameRound()
        }
    }, [])

    // Function to handle adding a new milestone
    const addGameRound = useCallback(() => {
        let data = _.cloneDeep(eventData?.[ARENA_EVENT_KEYS.game_rounds] ?? [])
        let obj
        if (
            [ARENA_EVENT_TYPES.classicRoulette, ARENA_EVENT_TYPES.russianRoulette].includes(
                eventData?.[ARENA_EVENT_KEYS.event_type]
            )
        ) {
            obj = {
                [ARENA_EVENT_KEYS.competition_start_date]: '',
                [ARENA_EVENT_KEYS.competition_end_date]: '',
                [ARENA_EVENT_KEYS.players_at_table]: '',
                [ARENA_EVENT_KEYS.no_of_winners]: '',
                [ARENA_EVENT_KEYS.competition_no_of_days]: '',
            }
        } else {
            obj = {
                [ARENA_EVENT_KEYS.competition_start_date]: '',
                [ARENA_EVENT_KEYS.competition_end_date]: '',
                [ARENA_EVENT_KEYS.cut_off]: 'percentage',
                [ARENA_EVENT_KEYS.points_required]: '',
                [ARENA_EVENT_KEYS.competition_no_of_days]: '',
            }
        }
        data.push(obj)
        updateMultipleEventData({
            [ARENA_EVENT_KEYS.game_rounds]: data,
        })
    }, [eventData, updateMultipleEventData])

    const updateGameRules = (index, e) => {
        let data = _.cloneDeep(eventData?.[ARENA_EVENT_KEYS.game_rounds])
        data[index][e?.target?.name] = e?.target?.value
        let gameRoundItem = _.cloneDeep(data[index])
        let start_date = getValidDate(
            gameRoundItem?.[ARENA_EVENT_KEYS.competition_start_date],
            'YYYY-MM-DD'
        )
        let end_date = getValidDate(
            gameRoundItem?.[ARENA_EVENT_KEYS.competition_end_date],
            'YYYY-MM-DD'
        )
        let duration = eventData?.[ARENA_EVENT_KEYS.competition_duration]

        let no_of_days = gameRoundItem?.[ARENA_EVENT_KEYS.competition_no_of_days]

        if (duration === 'set_by_exact_date' && start_date && end_date) {
            no_of_days = moment(end_date).diff(start_date, 'd') + 1
        }

        if (duration !== 'set_by_exact_date' && start_date && no_of_days) {
            end_date = moment(start_date)
                .add(no_of_days - 1, 'd')
                .format('YYYY-MM-DD')
        }

        gameRoundItem[ARENA_EVENT_KEYS.competition_start_date] = start_date
        gameRoundItem[ARENA_EVENT_KEYS.competition_end_date] = end_date
        gameRoundItem[ARENA_EVENT_KEYS.competition_no_of_days] = no_of_days

        data[index] = gameRoundItem

        updateMultipleEventData({
            [ARENA_EVENT_KEYS.game_rounds]: data,
        })
    }

    console.log('eventData*****', eventData?.[ARENA_EVENT_KEYS.game_rounds])

    return (
        <>
            <div className='px-32px'>
                <div className=''>
                    {/* -------------------- Game Rules -------------------- */}
                    <>
                        <div className='text-md-semibold text-sqGray900 mb-32px'>
                            Set Game Rules
                        </div>

                        {/* -------------------- Sold As -------------------- */}
                        <div className='text-sm-bold text-sqGray900 mb-18px'>Sold As</div>
                        <div className='row mb-18px gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Setter'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.sold_as_setter}
                                    value={eventData?.[ARENA_EVENT_KEYS.sold_as_setter]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.sold_as_setter]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Closer'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.sold_as_closer}
                                    value={eventData?.[ARENA_EVENT_KEYS.sold_as_closer]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.sold_as_closer]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Self-Gen'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.sold_as_self_gen}
                                    value={eventData?.[ARENA_EVENT_KEYS.sold_as_self_gen]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.sold_as_self_gen]
                                    }
                                />
                            </div>
                        </div>

                        {/* -------------------- Installed As -------------------- */}
                        <div className='text-sm-bold text-sqGray900 mb-18px'>Installed As</div>
                        <div className='row mb-18px gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Setter'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.installed_as_setter}
                                    value={eventData?.[ARENA_EVENT_KEYS.installed_as_setter]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.installed_as_setter]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Closer'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.installed_as_closer}
                                    value={eventData?.[ARENA_EVENT_KEYS.installed_as_closer]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.installed_as_closer]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Self-Gen'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.installed_as_self_gen}
                                    value={eventData?.[ARENA_EVENT_KEYS.installed_as_self_gen]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[
                                            ARENA_EVENT_KEYS.installed_as_self_gen
                                        ]
                                    }
                                />
                            </div>
                        </div>

                        {/* -------------------- KW Sold as -------------------- */}
                        <div className='text-sm-bold text-sqGray900 mb-18px'>KW Sold as</div>
                        <div className='row mb-18px gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Setter'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.kw_sold_as_setter}
                                    value={eventData?.[ARENA_EVENT_KEYS.kw_sold_as_setter]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.kw_sold_as_setter]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Closer'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.kw_sold_as_closer}
                                    value={eventData?.[ARENA_EVENT_KEYS.kw_sold_as_closer]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.kw_sold_as_closer]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Self-Gen'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.kw_sold_as_self_gen}
                                    value={eventData?.[ARENA_EVENT_KEYS.kw_sold_as_self_gen]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.kw_sold_as_self_gen]
                                    }
                                />
                            </div>
                        </div>

                        {/* -------------------- KW Installed -------------------- */}
                        <div className='text-sm-bold text-sqGray900 mb-18px'>KW Installed As</div>
                        <div className='row mb-18px gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Setter'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.kw_installed_as_setter}
                                    value={eventData?.[ARENA_EVENT_KEYS.kw_installed_as_setter]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[
                                            ARENA_EVENT_KEYS.kw_installed_as_setter
                                        ]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Closer'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.kw_installed_as_closer}
                                    value={eventData?.[ARENA_EVENT_KEYS.kw_installed_as_closer]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[
                                            ARENA_EVENT_KEYS.kw_installed_as_closer
                                        ]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Self-Gen'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.kw_installed_as_self_gen}
                                    value={eventData?.[ARENA_EVENT_KEYS.kw_installed_as_self_gen]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[
                                            ARENA_EVENT_KEYS.kw_installed_as_self_gen
                                        ]
                                    }
                                />
                            </div>
                        </div>

                        {/* -------------------- Others -------------------- */}
                        <div className='row mb-32px gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'New Hiring Leads'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.new_hiring_leads}
                                    value={eventData?.[ARENA_EVENT_KEYS.new_hiring_leads]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.new_hiring_leads]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'New Recruit Hired'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.new_recruit_hired}
                                    value={eventData?.[ARENA_EVENT_KEYS.new_recruit_hired]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.new_recruit_hired]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Hours Worked'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.hours_worked}
                                    value={eventData?.[ARENA_EVENT_KEYS.hours_worked]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.hours_worked]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Days On-Time'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.days_on_time}
                                    value={eventData?.[ARENA_EVENT_KEYS.days_on_time]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.days_on_time]
                                    }
                                />
                            </div>
                        </div>
                    </>

                    {/* -------------------- Competition Meta Data -------------------- */}
                    <div className='text-sm-bold text-sqGray900 mb-32px'>Competition Duration</div>
                    <div className='row mb-18px gap-2'>
                        <div className='col-sm-6'>
                            <CustomAutoCompleteDropdown
                                required
                                display='comma'
                                label={'Competition Days'}
                                options={ARENA_WEEK_DAYS}
                                name={ARENA_EVENT_KEYS.competition_days}
                                selectedOptions={eventData?.[ARENA_EVENT_KEYS.competition_days]}
                                onChange={updateEventData}
                                errorMessage={
                                    arenaEventErrorData?.[ARENA_EVENT_KEYS.competition_days]
                                }
                            />
                        </div>
                        <div className='col-sm'>
                            <CustomDropdown
                                required
                                label={'Duration'}
                                showClear={false}
                                options={ARENA_COMPETITION_DURATION}
                                name={ARENA_EVENT_KEYS.competition_duration}
                                value={eventData?.[ARENA_EVENT_KEYS.competition_duration]}
                                onChange={updateEventData}
                                errorMessage={
                                    arenaEventErrorData?.[ARENA_EVENT_KEYS.competition_duration]
                                }
                            />
                        </div>
                    </div>
                    <Show>
                        <Show.When
                            isVisible={[ARENA_EVENT_TYPES.ladder].includes(
                                eventData?.[ARENA_EVENT_KEYS.event_type]
                            )}
                        >
                            <>
                                <div className='row align-items-center gap-2'>
                                    <div className='col-sm-6 d-flex flex-column'>
                                        <CustomDatePicker
                                            required
                                            minDate={customMoment.todayDate()}
                                            label={'Start Date'}
                                            id={'One_time_Payment_Start_Date_Input_1'}
                                            placeholderText={'Start Date'}
                                            name={ARENA_EVENT_KEYS.competition_start_date}
                                            value={
                                                eventData?.[ARENA_EVENT_KEYS.competition_start_date]
                                            }
                                            onChange={updateEventData}
                                            errorMessage={
                                                arenaEventErrorData?.[
                                                    ARENA_EVENT_KEYS.competition_start_date
                                                ]
                                            }
                                        />
                                    </div>

                                    <Show>
                                        <Show.When
                                            isVisible={
                                                eventData?.[
                                                    ARENA_EVENT_KEYS.competition_duration
                                                ] == 'set_by_exact_date'
                                            }
                                        >
                                            <div className='col-sm d-flex flex-column'>
                                                <CustomDatePicker
                                                    required
                                                    label={'End Date'}
                                                    minDate={customMoment.date(
                                                        getValidDate(
                                                            eventData?.[
                                                                ARENA_EVENT_KEYS
                                                                    .competition_start_date
                                                            ],
                                                            'YYYY-MM-DD'
                                                        )
                                                    )}
                                                    placeholderText={'Start Date'}
                                                    name={ARENA_EVENT_KEYS.competition_end_date}
                                                    value={
                                                        eventData?.[
                                                            ARENA_EVENT_KEYS.competition_end_date
                                                        ]
                                                    }
                                                    onChange={updateEventData}
                                                    errorMessage={
                                                        arenaEventErrorData?.[
                                                            ARENA_EVENT_KEYS.competition_end_date
                                                        ]
                                                    }
                                                />
                                            </div>
                                        </Show.When>
                                        <Show.Else>
                                            <div className='col-sm d-flex flex-column'>
                                                <CustomInput
                                                    required
                                                    label={'No. of days'}
                                                    placeholder='0'
                                                    type={INPUT_TYPE.number}
                                                    restrictNegativeNum
                                                    name={ARENA_EVENT_KEYS.competition_no_of_days}
                                                    value={
                                                        eventData?.[
                                                            ARENA_EVENT_KEYS.competition_no_of_days
                                                        ]
                                                    }
                                                    onChange={updateEventData}
                                                    errorMessage={
                                                        arenaEventErrorData?.[
                                                            ARENA_EVENT_KEYS.competition_no_of_days
                                                        ]
                                                    }
                                                />
                                            </div>
                                        </Show.Else>
                                    </Show>
                                </div>
                            </>
                        </Show.When>
                        <Show.When
                            isVisible={[
                                ARENA_EVENT_TYPES.survivor,
                                ARENA_EVENT_TYPES.performance,
                                ARENA_EVENT_TYPES.classicRoulette,
                                ARENA_EVENT_TYPES.russianRoulette,
                            ].includes(eventData?.[ARENA_EVENT_KEYS.event_type])}
                        >
                            <>
                                <div className='text-md-semibold text-sqGray900 mb-8px'>
                                    Set Game Rules
                                </div>
                                {[
                                    ARENA_EVENT_TYPES.survivor,
                                    ARENA_EVENT_TYPES.performance,
                                ].includes(eventData?.[ARENA_EVENT_KEYS.event_type]) && (
                                    <div className='text-sm-medium sqGray600 mb-32px'>
                                        Players above the cutoff will win, players below the cutoff
                                        will be eliminated.
                                    </div>
                                )}
                                {eventData?.[ARENA_EVENT_KEYS.game_rounds]?.map((item, index) => (
                                    <>
                                        <div className='text-sm-bold text-sqGray900 mb-18px'>
                                            Round {index + 1}
                                        </div>

                                        <div className='row mb-18px gap-2'>
                                            <div className='col-sm d-flex flex-column'>
                                                <StartDateInput
                                                    gameRule={item}
                                                    index={index}
                                                    updateGameRules={updateGameRules}
                                                    arenaEventErrorData={arenaEventErrorData}
                                                />
                                            </div>
                                            <Show>
                                                <Show.When
                                                    isVisible={
                                                        eventData?.[
                                                            ARENA_EVENT_KEYS.competition_duration
                                                        ] == 'set_by_exact_date'
                                                    }
                                                >
                                                    <div className='col-sm d-flex flex-column'>
                                                        <EndDateInput
                                                            gameRule={item}
                                                            index={index}
                                                            updateGameRules={updateGameRules}
                                                            arenaEventErrorData={
                                                                arenaEventErrorData
                                                            }
                                                        />
                                                    </div>
                                                </Show.When>
                                                <Show.Else>
                                                    <div className='col-sm d-flex flex-column'>
                                                        <NoOfDaysInput
                                                            gameRule={item}
                                                            index={index}
                                                            updateGameRules={updateGameRules}
                                                            arenaEventErrorData={
                                                                arenaEventErrorData
                                                            }
                                                        />
                                                    </div>
                                                </Show.Else>
                                            </Show>
                                        </div>
                                        {/* -----------classicRoulette russianRoulette---------- */}
                                        <Show>
                                            <Show.When
                                                isVisible={[
                                                    ARENA_EVENT_TYPES.classicRoulette,
                                                    ARENA_EVENT_TYPES.russianRoulette,
                                                ].includes(
                                                    eventData?.[ARENA_EVENT_KEYS.event_type]
                                                )}
                                            >
                                                <div className='row mb-32px gap-2'>
                                                    <div className='col-sm d-flex flex-column'>
                                                        <PlayersAtTableInput
                                                            gameRule={item}
                                                            index={index}
                                                            updateGameRules={updateGameRules}
                                                            arenaEventErrorData={
                                                                arenaEventErrorData
                                                            }
                                                        />
                                                    </div>
                                                    <div className='col-sm'>
                                                        <NoOfWinnersInput
                                                            gameRule={item}
                                                            index={index}
                                                            updateGameRules={updateGameRules}
                                                            arenaEventErrorData={
                                                                arenaEventErrorData
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </Show.When>
                                            <Show.Else>
                                                <div className='row mb-32px gap-2'>
                                                    <div className='col-sm d-flex flex-column'>
                                                        <CutoffTypeInput
                                                            gameRule={item}
                                                            index={index}
                                                            updateGameRules={updateGameRules}
                                                            arenaEventErrorData={
                                                                arenaEventErrorData
                                                            }
                                                        />
                                                    </div>
                                                    <div className='col-sm'>
                                                        <PointsRequiredInput
                                                            gameRule={item}
                                                            index={index}
                                                            updateGameRules={updateGameRules}
                                                            arenaEventErrorData={
                                                                arenaEventErrorData
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </Show.Else>
                                        </Show>
                                    </>
                                ))}

                                <div
                                    className='d-flex gap-2 cursor-pointer '
                                    onClick={addGameRound}
                                >
                                    <div>
                                        <KTSVG
                                            path='/media/icons/duotune/arena/plus-square.svg'
                                            className='mb-2'
                                            svgClassName='w-20px h-20px'
                                        />
                                    </div>
                                    <div>
                                        <span className='text-sm-semibold text-sqPrimary600'>
                                            Add Another Round
                                        </span>
                                    </div>
                                </div>
                            </>
                        </Show.When>
                        <Show.When
                            isVisible={[ARENA_EVENT_TYPES.bracket].includes(
                                eventData?.[ARENA_EVENT_KEYS.event_type]
                            )}
                        >
                            <>
                                {/* {eventData?.[ARENA_EVENT_KEYS.users]?.map((item, index) => ( */}
                                <>
                                    {renderRounds(eventData?.[ARENA_EVENT_KEYS.users].length)}

                                    {/* <div className='row mb-18px gap-2'>
                                            <div className='col-sm d-flex flex-column'>
                                                <StartDateInput
                                                    gameRule={item}
                                                    index={index}
                                                    updateGameRules={updateGameRules}
                                                    arenaEventErrorData={arenaEventErrorData}
                                                />
                                            </div>
                                            <Show>
                                                <Show.When
                                                    isVisible={
                                                        eventData?.[
                                                            ARENA_EVENT_KEYS.competition_duration
                                                        ] == 'set_by_exact_date'
                                                    }
                                                >
                                                    <div className='col-sm d-flex flex-column'>
                                                        <EndDateInput
                                                            gameRule={item}
                                                            index={index}
                                                            updateGameRules={updateGameRules}
                                                            arenaEventErrorData={
                                                                arenaEventErrorData
                                                            }
                                                        />
                                                    </div>
                                                </Show.When>
                                                <Show.Else>
                                                    <div className='col-sm d-flex flex-column'>
                                                        <NoOfDaysInput
                                                            gameRule={item}
                                                            index={index}
                                                            updateGameRules={updateGameRules}
                                                            arenaEventErrorData={
                                                                arenaEventErrorData
                                                            }
                                                        />
                                                    </div>
                                                </Show.Else>
                                            </Show>
                                        </div> */}
                                </>
                                {/* ))} */}
                            </>
                        </Show.When>
                        <Show.When
                            isVisible={[ARENA_EVENT_TYPES.battle].includes(
                                eventData?.[ARENA_EVENT_KEYS.event_type]
                            )}
                        >
                            <>
                                {/* {assignMatcheUser?.map((item, index) => ( */}
                                <div className='row align-items-center gap-2'>
                                    <div className='col-sm-6 d-flex flex-column'>
                                        <CustomDatePicker
                                            required
                                            minDate={customMoment.todayDate()}
                                            label={'Start Date'}
                                            id={'One_time_Payment_Start_Date_Input_1'}
                                            placeholderText={'Start Date'}
                                            name={ARENA_EVENT_KEYS.competition_start_date}
                                            value={
                                                eventData?.[ARENA_EVENT_KEYS.competition_start_date]
                                            }
                                            onChange={updateEventData}
                                            errorMessage={
                                                arenaEventErrorData?.[
                                                    ARENA_EVENT_KEYS.competition_start_date
                                                ]
                                            }
                                        />
                                    </div>

                                    <Show>
                                        <Show.When
                                            isVisible={
                                                eventData?.[
                                                    ARENA_EVENT_KEYS.competition_duration
                                                ] == 'set_by_exact_date'
                                            }
                                        >
                                            <div className='col-sm d-flex flex-column'>
                                                <CustomDatePicker
                                                    required
                                                    label={'End Date'}
                                                    minDate={customMoment.date(
                                                        getValidDate(
                                                            eventData?.[
                                                                ARENA_EVENT_KEYS
                                                                    .competition_start_date
                                                            ],
                                                            'YYYY-MM-DD'
                                                        )
                                                    )}
                                                    placeholderText={'Start Date'}
                                                    name={ARENA_EVENT_KEYS.competition_end_date}
                                                    value={
                                                        eventData?.[
                                                            ARENA_EVENT_KEYS.competition_end_date
                                                        ]
                                                    }
                                                    onChange={updateEventData}
                                                    errorMessage={
                                                        arenaEventErrorData?.[
                                                            ARENA_EVENT_KEYS.competition_end_date
                                                        ]
                                                    }
                                                />
                                            </div>
                                        </Show.When>
                                        <Show.Else>
                                            <div className='col-sm d-flex flex-column'>
                                                <CustomInput
                                                    required
                                                    label={'No. of days'}
                                                    placeholder='0'
                                                    type={INPUT_TYPE.number}
                                                    restrictNegativeNum
                                                    name={ARENA_EVENT_KEYS.competition_no_of_days}
                                                    value={
                                                        eventData?.[
                                                            ARENA_EVENT_KEYS.competition_no_of_days
                                                        ]
                                                    }
                                                    onChange={updateEventData}
                                                    errorMessage={
                                                        arenaEventErrorData?.[
                                                            ARENA_EVENT_KEYS.competition_no_of_days
                                                        ]
                                                    }
                                                />
                                            </div>
                                        </Show.Else>
                                    </Show>
                                </div>
                                {/* ))} */}
                            </>
                        </Show.When>
                    </Show>
                </div>
            </div>
        </>
    )
}

export default SetGameRules

const StartDateInput = ({index, gameRule, arenaEventErrorData, updateGameRules}) => (
    <CustomDatePicker
        required
        minDate={customMoment.todayDate()}
        label={'Start Date'}
        placeholderText={'Start Date'}
        name={ARENA_EVENT_KEYS.competition_start_date}
        value={gameRule?.[ARENA_EVENT_KEYS.competition_start_date]}
        onChange={(e) => {
            updateGameRules(index, e)
        }}
        errorMessage={
            arenaEventErrorData?.[ARENA_EVENT_KEYS.game_rounds]?.[index]?.[
                ARENA_EVENT_KEYS.competition_start_date
            ]
        }
    />
)
const EndDateInput = ({index, gameRule, arenaEventErrorData, updateGameRules}) => (
    <CustomDatePicker
        required
        label={'End Date'}
        minDate={customMoment.date(
            getValidDate(gameRule?.[ARENA_EVENT_KEYS.competition_start_date], 'YYYY-MM-DD')
        )}
        placeholderText={'End Date'}
        name={ARENA_EVENT_KEYS.competition_end_date}
        value={gameRule?.[ARENA_EVENT_KEYS.competition_end_date]}
        onChange={(e) => updateGameRules(index, e)}
        errorMessage={
            arenaEventErrorData?.[ARENA_EVENT_KEYS.game_rounds]?.[index]?.[
                ARENA_EVENT_KEYS.competition_end_date
            ]
        }
    />
)

const NoOfDaysInput = ({index, gameRule, arenaEventErrorData, updateGameRules}) => (
    <CustomInput
        required
        label={'No. of days'}
        placeholder='0'
        type={INPUT_TYPE.number}
        // restrictNegativeNum
        name={ARENA_EVENT_KEYS.competition_no_of_days}
        value={gameRule?.[ARENA_EVENT_KEYS.competition_no_of_days]}
        onChange={(e) => updateGameRules(index, e)}
        errorMessage={
            arenaEventErrorData?.[ARENA_EVENT_KEYS.game_rounds]?.[index]?.[
                ARENA_EVENT_KEYS.competition_no_of_days
            ]
        }
    />
)
const CutoffTypeInput = ({index, gameRule, arenaEventErrorData, updateGameRules}) => (
    <CustomDropdown
        required
        label={'Select Reward Type'}
        options={ARENA_COMPETITION_CUTOFF_WIN_CRITERIA}
        showClear={false}
        name={ARENA_EVENT_KEYS.cut_off}
        value={gameRule?.[ARENA_EVENT_KEYS.cut_off]}
        onChange={(e) => updateGameRules(index, e)}
        errorMessage={
            arenaEventErrorData?.[ARENA_EVENT_KEYS.game_rounds]?.[index]?.[ARENA_EVENT_KEYS.cut_off]
        }
    />
)

const PointsRequiredInput = ({index, gameRule, arenaEventErrorData, updateGameRules}) => (
    <CustomInput
        required
        label={'Points Required '}
        placeholder='0'
        type={INPUT_TYPE.number}
        restrictNegativeNum
        name={ARENA_EVENT_KEYS.points_required}
        value={gameRule?.[ARENA_EVENT_KEYS.points_required]}
        // onChange={(e) => updateGameRules(index, e)}
        onChange={(e) => {
            const value = e.target.value
            const maxAllowedValue = 100

            if (/^\d+$/.test(value) && Number(value) <= maxAllowedValue) {
                updateGameRules(index, e)
            } else if (value === '') {
                updateGameRules(index, e)
            }
        }}
        suffixText={gameRule?.[ARENA_EVENT_KEYS.cut_off] == 'percentage' ? '%' : ''}
        errorMessage={
            arenaEventErrorData?.[ARENA_EVENT_KEYS.game_rounds]?.[index]?.[
                ARENA_EVENT_KEYS.points_required
            ]
        }
    />
)

const PlayersAtTableInput = ({index, gameRule, arenaEventErrorData, updateGameRules}) => (
    <>
        <CustomInput
            required
            label={'Players at table '}
            placeholder='0'
            type={INPUT_TYPE.number}
            // restrictNegativeNum
            name={ARENA_EVENT_KEYS.players_at_table}
            value={gameRule?.[ARENA_EVENT_KEYS.players_at_table]}
            onChange={(e) => updateGameRules(index, e)}
            errorMessage={
                arenaEventErrorData?.[ARENA_EVENT_KEYS.game_rounds]?.[index]?.[
                    ARENA_EVENT_KEYS.players_at_table
                ]
            }
        />
    </>
)
const NoOfWinnersInput = ({index, gameRule, arenaEventErrorData, updateGameRules}) => (
    <CustomInput
        required
        label={'No. of Winners'}
        placeholder='0'
        type={INPUT_TYPE.number}
        // restrictNegativeNum
        name={ARENA_EVENT_KEYS.no_of_winners}
        value={gameRule?.[ARENA_EVENT_KEYS.no_of_winners]}
        onChange={(e) => updateGameRules(index, e)}
        errorMessage={
            arenaEventErrorData?.[ARENA_EVENT_KEYS.game_rounds]?.[index]?.[
                ARENA_EVENT_KEYS.no_of_winners
            ]
        }
    />
)

export const renderRounds = (participantsCount) => {
    const rounds = [
        {roundName: `Round`, participants: participantsCount}, // 64 participants initially
        {roundName: `Round`, participants: Math.floor(participantsCount / 2)}, // 32 participants
        {roundName: `Round`, participants: Math.floor(participantsCount / 4)}, // 16 participants
        {roundName: `Quarter Finals`, participants: Math.floor(participantsCount / 8)}, // 8 participants
        {roundName: `Semi Finals`, participants: Math.floor(participantsCount / 16)}, // 4 participants
        {roundName: `Finals`, participants: Math.floor(participantsCount / 32)}, // 2 participants
    ]

    return (
        <div>
            {rounds.map((round, index) => (
                <div className='text-sm-bold text-sqGray900 mb-18px' key={index}>
                    {round.roundName === 'Round'
                        ? `Round ${index + 1}/6 - ${round.participants} participants`
                        : `${round.roundName} - ${round.participants} participants`}
                </div>
            ))}
        </div>
    )
}
