import {fontsFamily} from '../../assets/fonts/fonts'

const CartoonText = ({
    className,
    style,
    children,
    fontWeight = 500,
    fontSize = '10px',
    isStroke = true,
    color = 'white',
    strokeColor = 'black',
    borderWidth = '1px',
    noStrokeColor = 'black',
    lineHeight = '18px',
}) => {
    return (
        <span
            className={`${className}`}
            style={{
                fontFamily: fontsFamily.digitalt,
                lineHeight: lineHeight,
                fontSize,
                fontWeight,
                ...(isStroke
                    ? {
                          WebkitTextStrokeWidth: borderWidth,
                          WebkitTextFillColor: color,
                          WebkitTextStrokeColor: strokeColor,
                      }
                    : {
                          color: noStrokeColor, // Apply this color when stroke is false
                      }),
                ...style,
            }}
        >
            {children}
        </span>
    )
}

export default CartoonText
