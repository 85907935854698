import React, {useCallback, useEffect, useMemo, useState} from 'react'
import NewEvent from './NewEvent'
import AddParticipants from './AddParticipants'
import SetGameRules from './SetGameRules'
import SetGameRewards from './SetGameRewards'
import CustomStepper from '../../../../../../customComponents/customStepper/CustomStepper'
import SetGameRulesBracket from '../bracket/SetGameRulesBracket'
import SetRewardsBracket from '../bracket/SetRewardsBracket'
import CustomModal2 from '../../../../../../customComponents/customModal/CustomModal2'
import AssignMatchesBattle from '../battle/AssignMatchesBattle'
import SetGameRulesBattle from '../battle/SetGameRulesBattle'
import SetRewardsBattle from '../battle/SetRewardsBattle'
import SetGameRulesRussian from '../russianRoulette/SetGameRulesRussian'
import SetRewardsRussian from '../russianRoulette/SetRewardsRussian'
import SetGameRulesStandardRoulette from '../standardRoulette/SetGameRulesStandardRoulette'
import SetRewardsStandardRoulette from '../standardRoulette/SetRewardsStandardRoulette'
import SetGameChampion from '../champion/SetGameChampion'
import SetGameRulesSurvivor from '../survivor/SetGameRulesSurvivor'
import SetRewardsSurvivor from '../survivor/SetRewardsSurvivor'
import SetGameRulesPerformance from '../performance/SetGameRulesPerformance'
import SetRewardsPerformance from '../performance/SetRewardsPerformance'
import SetRewardsChampion from '../champion/SetRewardsChampion'
import Show from '../../../../../../customComponents/show/Show'
import {
    ARENA_EVENT_TYPES,
    getArenaEventMetaData,
    getValidDate,
    MAIN_POSITTIONS_ID,
} from '../../../../../../constants/constants'
import {v4 as uuidv4} from 'uuid'
import CustomButton2, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../../../customComponents/customButtton/CustomButton2'
import useValidation from '../../../../../../hooks/useValidation'
import {saveArenaEventValidation} from '../../../../../../validations/validations'
import {getUsersBasedOnPositionAndOfficesService} from '../../../../../../services/Services'
import useAPI from '../../../../../../hooks/useAPI'
import _, {compact} from 'lodash'
import useOfficeLocation from '../../../../../../hooks/useOfficeLocation'
import ArenaCreateEventTopCard from '../ArenaCreateEventTopCard'
import {mongo} from '../../../../../../mongodb/mongoDB'
import CustomLoader from '../../../../../../customComponents/customLoader/CustomLoader'
import CustomToast from '../../../../../../customComponents/customToast/CustomToast'
import AssignMatches from './AssignMatches'
import moment from 'moment'

export const ARENA_EVENT_STEPS = {
    newEvent: 'New Event',
    addParticipants: 'Add Participants',
    assignMatches: 'Assign Matches',
    setGameRules: 'Set Game Rules',
    setRewards: 'Set Rewards',
}

export const ARENA_EVENT_KEYS = {
    // New Events
    event_type: 'event_type',
    event_mode: 'event_mode',
    event_name: 'event_name',
    event_description: 'event_description',

    // Add Participants
    offices: 'offices',
    users: 'users',

    // Set Game Rules

    // Company Type: Solar
    sold_as_setter: 'sold_as_setter',
    sold_as_closer: 'sold_as_closer',
    sold_as_self_gen: 'sold_as_self_gen',
    installed_as_setter: 'installed_as_setter',
    installed_as_closer: 'installed_as_closer',
    installed_as_self_gen: 'installed_as_self_gen',
    kw_sold_as_setter: 'kw_sold_as_setter',
    kw_sold_as_closer: 'kw_sold_as_closer',
    kw_sold_as_self_gen: 'kw_sold_as_self_gen',
    kw_installed_as_setter: 'kw_installed_as_setter',
    kw_installed_as_closer: 'kw_installed_as_closer',
    kw_installed_as_self_gen: 'kw_installed_as_self_gen',

    new_hiring_leads: 'new_hiring_leads',
    new_recruit_hired: 'new_recruit_hired',
    hours_worked: 'hours_worked',
    days_on_time: 'days_on_time',

    competition_days: 'competition_days',
    competition_duration: 'competition_duration',
    competition_start_date: 'competition_start_date',
    competition_end_date: 'competition_end_date',
    competition_no_of_days: 'competition_no_of_days',

    game_rounds: 'game_rounds',
    cut_off: 'cut_off',

    players_at_table: 'players_at_table', //classic
    no_of_winners: 'no_of_winners', //classic

    //AssignMatches
    assign_player_mode: 'assign_player_mode',
    matches: 'matches',

    // Set Game Rewards
    rewards_data: 'rewards_data',
    reward_type: 'reward_type',
    reward: 'reward',
    points_required: 'points_required',
    hide_reward: 'hide_reward',
}

export const arenaEventData = {
    [ARENA_EVENT_KEYS.sold_as_setter]: 0,
    [ARENA_EVENT_KEYS.sold_as_closer]: 0,
    [ARENA_EVENT_KEYS.sold_as_self_gen]: 0,
    [ARENA_EVENT_KEYS.installed_as_setter]: 0,
    [ARENA_EVENT_KEYS.installed_as_closer]: 0,
    [ARENA_EVENT_KEYS.installed_as_self_gen]: 0,
    [ARENA_EVENT_KEYS.kw_sold_as_setter]: 0,
    [ARENA_EVENT_KEYS.kw_sold_as_closer]: 0,
    [ARENA_EVENT_KEYS.kw_sold_as_self_gen]: 0,
    [ARENA_EVENT_KEYS.kw_installed_as_setter]: 0,
    [ARENA_EVENT_KEYS.kw_installed_as_closer]: 0,
    [ARENA_EVENT_KEYS.kw_installed_as_self_gen]: 0,
    [ARENA_EVENT_KEYS.new_hiring_leads]: 0,
    [ARENA_EVENT_KEYS.new_recruit_hired]: 0,
    [ARENA_EVENT_KEYS.hours_worked]: 0,
    [ARENA_EVENT_KEYS.days_on_time]: 0,
    [ARENA_EVENT_KEYS.competition_duration]: 'set_by_exact_date',
    [ARENA_EVENT_KEYS.competition_days]: [1, 2, 3, 4, 5, 6, 7],
    [ARENA_EVENT_KEYS.rewards_data]: [],
    [ARENA_EVENT_KEYS.game_rounds]: [],
    [ARENA_EVENT_KEYS.assign_player_mode]: 'Manual',
    [ARENA_EVENT_KEYS.matches]: [],
}

const AddArenaEvent = ({open, handleClose}) => {
    const [activeStep, setActiveStep] = useState(0)
    const [eventData, setEventData] = useState(arenaEventData)
    const [validateArenaEventData, arenaEventErrorData, setBeginValidating] = useValidation({
        ignoreKeys: [ARENA_EVENT_KEYS.game_rounds],
    })

    const [selectedOffices, setSelectedOffices] = useState(['all'])
    const [selectedPositions, setSelectedPositions] = useState(['all'])
    const [assignMatcheUser, setAssignMatcheUser] = useState([])
    const [foundedUsers, setFoundedUsers] = useState([])
    const [officeList] = useOfficeLocation('all', true, true)
    const [viewParticipants, setViewParticipants] = useState(false)
    console.log('assignMatcheUser', assignMatcheUser)
    console.log('eventData', eventData)

    const {
        initAPI: initGetUserBasedOnOfficeAndPosition,
        callAPI: callGetUserBasedOnOfficeAndPosition,
        loading: getUserBasedOnOfficeAndPositionLoading,
    } = useAPI()
    const {callAPI: callSaveEventDataApi, loading: saveEventDataLoading} = useAPI()
    const steps = useMemo(
        () => [
            {label: ARENA_EVENT_STEPS.newEvent, value: 'add'},
            {label: ARENA_EVENT_STEPS.addParticipants, value: 'two'},
            ...(eventData?.event_type === 'Bracket' || eventData?.event_type === 'Battle'
                ? [{label: ARENA_EVENT_STEPS.assignMatches, value: 'four'}]
                : []),
            {label: ARENA_EVENT_STEPS.setGameRules, value: 'three'},
            {label: ARENA_EVENT_STEPS.setRewards, value: 'five'},
        ],
        [eventData?.event_type]
    )

    useEffect(() => {
        setBeginValidating(false)
    }, [activeStep])

    useEffect(() => {
        const handler = _.debounce(() => {
            getUsers()
        }, 1000)
        handler()
        return () => {
            handler.cancel()
        }
    }, [selectedPositions, selectedOffices, eventData?.event_mode])

    useEffect(() => {
        if (assignMatcheUser?.length > 0) {
            updateMultipleEventData({
                [ARENA_EVENT_KEYS.matches]: assignMatcheUser,
            })
        }
    }, [assignMatcheUser])

    const getUsers = useCallback(() => {
        if (selectedOffices?.length > 0 || selectedPositions?.length > 0) {
            let body = {
                office_id: selectedOffices?.some((item) => item == 'all') ? 'All' : selectedOffices,
                position_id: selectedPositions?.some((item) => item == 'all')
                    ? 'All'
                    : selectedPositions,
            }
            initGetUserBasedOnOfficeAndPosition().then((signal) => {
                callGetUserBasedOnOfficeAndPosition(
                    getUsersBasedOnPositionAndOfficesService(
                        body.office_id,
                        body.position_id,
                        signal
                    )
                ).then((res) => {
                    let userData = res?.data?.map((item) => ({
                        user_id: item?.id,
                        user_name: `${item?.first_name} ${item?.last_name}`,
                        user_image: item?.image,
                        position_name:
                            res?.position_id == MAIN_POSITTIONS_ID.closer ? 'Closer' : 'Setter',
                        position_id: item?.position_id,
                        sub_position_name: item?.position_detail?.position_name,
                        sub_position_id: item?.sub_position_id,
                        office_id: item?.office_id,
                    }))
                    setFoundedUsers(userData)

                    let officeData = []

                    if (selectedOffices?.includes('all')) {
                        officeData = officeList
                            ?.filter((item) => item?.value != 'all')
                            ?.map((item) => {
                                const officeUsers = userData.filter(
                                    (_i) => _i.office_id == item?.value
                                )
                                return {
                                    office_id: item?.value,
                                    office_name: item?.name,
                                    users: officeUsers,
                                }
                            })
                    } else {
                        officeData = selectedOffices?.map((officeId) => {
                            let item = officeList?.find(
                                (officeItem) => officeItem?.value == officeId
                            )

                            const officeUsers = userData.filter(
                                (item) => item.office_id == officeId
                            )

                            return {
                                office_id: item?.value,
                                office_name: item?.name,
                                users: officeUsers,
                            }
                        })
                    }

                    updateMultipleEventData({
                        [ARENA_EVENT_KEYS.users]: userData,
                        [ARENA_EVENT_KEYS.offices]: officeData,
                    })
                })
            })
        }
    }, [
        selectedOffices,
        selectedPositions,
        initGetUserBasedOnOfficeAndPosition,
        callGetUserBasedOnOfficeAndPosition,
        officeList,
    ])

    const currentActiveStepName = useMemo(() => {
        return steps?.[activeStep]?.label
    }, [activeStep, steps])

    useEffect(() => {
        if (arenaEventErrorData?.beginValidating) {
            validateArenaEventData(
                saveArenaEventValidation({
                    data: eventData,
                    step: currentActiveStepName,
                    selectedOffices,
                    selectedPositions,
                })
            )
        }
    }, [eventData, selectedOffices, selectedPositions])

    const modalTitle = useMemo(() => {
        return (
            `Create an event | ` +
            (currentActiveStepName == ARENA_EVENT_STEPS.newEvent
                ? 'New Event'
                : currentActiveStepName == ARENA_EVENT_STEPS.addParticipants
                ? 'Add Participants'
                : currentActiveStepName == ARENA_EVENT_STEPS.setGameRules
                ? 'Set Game Rules'
                : currentActiveStepName == ARENA_EVENT_STEPS.assignMatches
                ? 'Assign Matches'
                : currentActiveStepName == ARENA_EVENT_STEPS.setRewards
                ? 'Set Rewards'
                : '')
        )
    }, [currentActiveStepName])

    const buttonLabel = useMemo(() => {
        switch (currentActiveStepName) {
            case ARENA_EVENT_STEPS.newEvent:
                return 'Next (Add Participants)'
            case ARENA_EVENT_STEPS.addParticipants:
                return eventData?.event_type == ARENA_EVENT_TYPES.bracket ||
                    eventData?.event_type == ARENA_EVENT_TYPES.battle
                    ? 'Next (Assign Matches)'
                    : 'Next (Set Game Rules)'
            case ARENA_EVENT_STEPS.assignMatches:
                return 'Next (Set Game Rules)'
            case ARENA_EVENT_STEPS.setGameRules:
                return 'Next (Set Rewards)'
            default:
                return 'Save and Publish'
        }
    }, [currentActiveStepName])

    const isLastStep = useMemo(() => {
        return activeStep == steps?.length - 1
    }, [activeStep, steps?.length])

    // const updateEventData = (e) => {
    //     const {name, value} = e?.target

    //     setEventData((val) => {
    //         // Reset end_date and no_of_days if competition_duration changes
    //         if (name === ARENA_EVENT_KEYS.competition_duration) {
    //             return {
    //                 ...val,
    //                 [name]: value,
    //                 [ARENA_EVENT_KEYS.competition_end_date]: null,
    //                 [ARENA_EVENT_KEYS.competition_no_of_days]: '',
    //             }
    //         }

    //         // Update other fields normally
    //         return {
    //             ...val,
    //             [name]: value,
    //         }
    //     })
    // }

    const getArenaFormattedValue = (name, value) => {
        if (
            [ARENA_EVENT_KEYS.competition_no_of_days, ARENA_EVENT_KEYS.new_hiring_leads].includes(
                name
            )
        ) {
            return Number(value)
        } else if (
            [
                ARENA_EVENT_KEYS.competition_start_date,
                ARENA_EVENT_KEYS.competition_end_date,
            ].includes(name)
        ) {
            return moment(getValidDate(value, 'YYYY-MM-DD 00:00:00')).valueOf()
        }
        return value
    }
    const updateEventData = (e) => {
        if (!e?.target) {
            return
        }
        const {name, value} = e.target

        setEventData((val) => {
            // Reset end_date and no_of_days if competition_duration changes
            if (name === ARENA_EVENT_KEYS.competition_duration) {
                return {
                    ...val,
                    [name]: getArenaFormattedValue(name, value),
                    [ARENA_EVENT_KEYS.competition_end_date]: null,
                    [ARENA_EVENT_KEYS.competition_no_of_days]: '',
                }
            }
            // Update other fields normally
            return {
                ...val,
                [name]: getArenaFormattedValue(name, value),
            }
        })
    }

    const updateMultipleEventData = (data) => {
        setEventData((val) => ({
            ...val,
            ...data,
        }))
    }

    const onSaveAndPublish = useCallback(() => {
        let data = _.cloneDeep(eventData)

        callSaveEventDataApi(
            mongo.addArenaEvent({
                _id: uuidv4(),
                ...data,
                competition_start_date: getArenaFormattedValue(
                    'competition_start_date',
                    data?.competition_start_date
                ),
                competition_end_date: getArenaFormattedValue(
                    'competition_end_date',
                    data?.competition_end_date
                ),
            })
        )
            .then(() => {
                CustomToast.success('Event created')
                handleClose()
            })
            .catch((e) => {
                CustomToast.error(e)
            })
    }, [callSaveEventDataApi, eventData, handleClose])

    const nextStep = useCallback(() => {
        setActiveStep((val) => val + 1)
    }, [])

    const checkIsBlankRewardData = (data) => {
        for (const reward of data) {
            for (const key in reward) {
                if (key != ARENA_EVENT_KEYS.hide_reward && reward[key]) return true
            }
        }
        return false
    }

    const checkIsGameData = (data) => {
        if (!Array.isArray(data)) {
            return false
        }

        for (const reward of data) {
            for (const key in reward) {
                if (reward[key]) return true
            }
        }

        return false
    }
    const onNextPress = useCallback(() => {
        if (currentActiveStepName == ARENA_EVENT_STEPS.newEvent) {
            validateArenaEventData(
                saveArenaEventValidation({data: eventData, step: ARENA_EVENT_STEPS.newEvent})
            ).then((res) => {
                if (res?.isValidate) nextStep()
            })
        } else if (currentActiveStepName == ARENA_EVENT_STEPS.addParticipants) {
            validateArenaEventData(
                saveArenaEventValidation({
                    data: eventData,
                    step: ARENA_EVENT_STEPS.addParticipants,
                    selectedOffices,
                    selectedPositions,
                })
            ).then((res) => {
                if (res?.isValidate) nextStep()
            })
        } else if (currentActiveStepName == ARENA_EVENT_STEPS.assignMatches) {
            validateArenaEventData(
                saveArenaEventValidation({
                    data: eventData,
                    assignMatcheUser,
                    step: ARENA_EVENT_STEPS.assignMatches,
                })
            ).then((res) => {
                if (res?.isValidate) nextStep()
            })
        } else if (currentActiveStepName == ARENA_EVENT_STEPS.setGameRules) {
            validateArenaEventData(
                saveArenaEventValidation({
                    data: eventData,
                    step: ARENA_EVENT_STEPS.setGameRules,
                })
            ).then((res) => {
                if (res?.isValidate) {
                    if (
                        [
                            ARENA_EVENT_TYPES.survivor,
                            ARENA_EVENT_TYPES.performance,
                            ARENA_EVENT_TYPES.classicRoulette,
                            ARENA_EVENT_TYPES.russianRoulette,
                        ].includes(eventData?.[ARENA_EVENT_KEYS.event_type])
                    ) {
                        if (!checkIsGameData(res?.game_rounds)) {
                            const finalData = Array(
                                eventData?.[ARENA_EVENT_KEYS.game_rounds]?.length || 0
                            )
                                .fill(null)
                                .map(() => ({
                                    reward_type: 'Cash',
                                    reward: null,
                                    hide_reward: false,
                                }))
                            updateMultipleEventData({
                                [ARENA_EVENT_KEYS.rewards_data]: finalData,
                            })

                            nextStep()
                        }
                    } else {
                        nextStep()
                    }
                }
            })
        } else if (currentActiveStepName == ARENA_EVENT_STEPS.setRewards) {
            validateArenaEventData(
                saveArenaEventValidation({
                    data: eventData,
                    step: ARENA_EVENT_STEPS.setRewards,
                })
            ).then((res) => {
                if (!checkIsBlankRewardData(res?.rewards_data)) {
                    onSaveAndPublish()
                }
            })
        }
    }, [
        currentActiveStepName,
        eventData,
        nextStep,
        onSaveAndPublish,
        selectedOffices,
        selectedPositions,
        validateArenaEventData,
        assignMatcheUser,
    ])

    const eventMetaData = useMemo(
        () => getArenaEventMetaData(eventData?.event_type),
        [eventData?.event_type]
    )
    return (
        <>
            <CustomModal2
                show={open}
                onHide={handleClose}
                title={modalTitle}
                maxWidth={'950'}
                header_px='32px'
                header_py='32px'
                body_px='0px'
                body_py='0px'
                headerBorder={false}
            >
                <CustomLoader visible={saveEventDataLoading} full />
                <div>
                    <CustomStepper
                        steps={steps}
                        activeStep={activeStep}
                        onTabClick={(i) => setActiveStep(i)}
                    />

                    <div className='pb-0 py-32px h-400px overflow-auto'>
                        <div className='px-32px '>
                            <ArenaCreateEventTopCard
                                visible={currentActiveStepName != ARENA_EVENT_STEPS.newEvent}
                                showParticipants={
                                    currentActiveStepName != ARENA_EVENT_STEPS.addParticipants
                                }
                                eventData={eventData}
                                eventMetaData={eventMetaData}
                            />
                        </div>
                        <Show>
                            {/* -------------------- Step: New Event -------------------- */}
                            <Show.When
                                isVisible={currentActiveStepName == ARENA_EVENT_STEPS.newEvent}
                            >
                                <NewEvent
                                    eventData={eventData}
                                    updateEventData={updateEventData}
                                    arenaEventErrorData={arenaEventErrorData}
                                />
                            </Show.When>

                            {/* -------------------- Step: Add Participants -------------------- */}
                            <Show.When
                                isVisible={
                                    currentActiveStepName == ARENA_EVENT_STEPS.addParticipants
                                }
                            >
                                <AddParticipants
                                    foundedUsers={foundedUsers}
                                    officeList={officeList}
                                    eventData={eventData}
                                    updateEventData={updateEventData}
                                    arenaEventErrorData={arenaEventErrorData}
                                    selectedOffices={selectedOffices}
                                    setSelectedOffices={setSelectedOffices}
                                    selectedPositions={selectedPositions}
                                    getUserBasedOnOfficeAndPositionLoading={
                                        getUserBasedOnOfficeAndPositionLoading
                                    }
                                    setSelectedPositions={setSelectedPositions}
                                    viewParticipants={viewParticipants}
                                    setViewParticipants={setViewParticipants}
                                />
                            </Show.When>

                            {/* -------------------- Step: Assign Matches -------------------- */}
                            <Show.When
                                isVisible={currentActiveStepName == ARENA_EVENT_STEPS.assignMatches}
                            >
                                <Show>
                                    <Show.When
                                        isVisible={
                                            eventData?.event_type == ARENA_EVENT_TYPES.bracket ||
                                            eventData?.event_type == ARENA_EVENT_TYPES.battle
                                        }
                                    >
                                        <AssignMatches
                                            eventData={eventData}
                                            updateEventData={updateEventData}
                                            setAssignMatcheUser={(data) => {
                                                setAssignMatcheUser(data)
                                                updateEventData({
                                                    [ARENA_EVENT_KEYS.matches]: data,
                                                })
                                            }}
                                            assignMatcheUser={assignMatcheUser}
                                            updateMultipleEventData={updateMultipleEventData}
                                        />
                                    </Show.When>
                                    <Show.When
                                        isVisible={
                                            eventData?.event_type == ARENA_EVENT_TYPES.battle
                                        }
                                    >
                                        <AssignMatchesBattle />
                                    </Show.When>
                                </Show>
                            </Show.When>

                            {/* -------------------- Step: Set Game Rules -------------------- */}
                            <Show.When
                                isVisible={currentActiveStepName == ARENA_EVENT_STEPS.setGameRules}
                            >
                                <Show>
                                    <Show.When
                                        isVisible={[
                                            ARENA_EVENT_TYPES.ladder,
                                            ARENA_EVENT_TYPES.champion,
                                            ARENA_EVENT_TYPES.survivor,
                                            ARENA_EVENT_TYPES.performance,
                                            ARENA_EVENT_TYPES.bracket,
                                            ARENA_EVENT_TYPES.battle,
                                            ARENA_EVENT_TYPES.classicRoulette,
                                            ARENA_EVENT_TYPES.russianRoulette,
                                        ].includes(eventData?.[ARENA_EVENT_KEYS.event_type])}
                                    >
                                        <SetGameRules
                                            eventData={eventData}
                                            updateEventData={updateEventData}
                                            arenaEventErrorData={arenaEventErrorData}
                                            updateMultipleEventData={updateMultipleEventData}
                                            assignMatcheUser={assignMatcheUser}
                                        />
                                    </Show.When>
                                    <Show.When
                                        isVisible={
                                            eventData?.event_type == ARENA_EVENT_TYPES.champion
                                        }
                                    >
                                        <SetGameChampion />
                                    </Show.When>
                                    <Show.When
                                        isVisible={
                                            eventData?.event_type == ARENA_EVENT_TYPES.survivor
                                        }
                                    >
                                        <SetGameRulesSurvivor />
                                    </Show.When>
                                    <Show.When
                                        isVisible={
                                            eventData?.event_type == ARENA_EVENT_TYPES.performance
                                        }
                                    >
                                        <SetGameRulesPerformance />
                                    </Show.When>
                                    <Show.When
                                        isVisible={
                                            eventData?.event_type == ARENA_EVENT_TYPES.battle
                                        }
                                    >
                                        <SetGameRulesBattle />
                                    </Show.When>
                                    <Show.When
                                        isVisible={
                                            eventData?.event_type == ARENA_EVENT_TYPES.bracket
                                        }
                                    >
                                        <SetGameRulesBracket />
                                    </Show.When>
                                    <Show.When
                                        isVisible={
                                            eventData?.event_type ==
                                            ARENA_EVENT_TYPES.classicRoulette
                                        }
                                    >
                                        <SetGameRulesStandardRoulette />
                                    </Show.When>
                                    <Show.When
                                        isVisible={
                                            eventData?.event_type ==
                                            ARENA_EVENT_TYPES.russianRoulette
                                        }
                                    >
                                        <SetGameRulesRussian />
                                    </Show.When>
                                </Show>
                            </Show.When>

                            {/* -------------------- Step: Set Rewards -------------------- */}

                            <Show.When
                                isVisible={currentActiveStepName == ARENA_EVENT_STEPS.setRewards}
                            >
                                <Show>
                                    <Show.When
                                        isVisible={[
                                            ARENA_EVENT_TYPES.ladder,
                                            ARENA_EVENT_TYPES.champion,
                                            ARENA_EVENT_TYPES.survivor,
                                            ARENA_EVENT_TYPES.performance,
                                            ARENA_EVENT_TYPES.bracket,
                                            ARENA_EVENT_TYPES.battle,
                                            ARENA_EVENT_TYPES.classicRoulette,
                                            ARENA_EVENT_TYPES.russianRoulette,
                                        ].includes(eventData?.[ARENA_EVENT_KEYS.event_type])}
                                    >
                                        <SetGameRewards
                                            eventData={eventData}
                                            updateEventData={updateEventData}
                                            arenaEventErrorData={arenaEventErrorData}
                                            updateMultipleEventData={updateMultipleEventData}
                                            eventMetaData={eventMetaData}
                                        />
                                    </Show.When>
                                    <Show.When
                                        isVisible={
                                            eventData?.event_type == ARENA_EVENT_TYPES.champion
                                        }
                                    >
                                        <SetRewardsChampion />
                                    </Show.When>
                                    <Show.When
                                        isVisible={
                                            eventData?.event_type == ARENA_EVENT_TYPES.survivor
                                        }
                                    >
                                        <SetRewardsSurvivor />
                                    </Show.When>
                                    <Show.When
                                        isVisible={
                                            eventData?.event_type == ARENA_EVENT_TYPES.performance
                                        }
                                    >
                                        <SetRewardsPerformance />
                                    </Show.When>
                                    <Show.When
                                        isVisible={
                                            eventData?.event_type == ARENA_EVENT_TYPES.battle
                                        }
                                    >
                                        <SetRewardsBattle />
                                    </Show.When>
                                    <Show.When
                                        isVisible={
                                            eventData?.event_type == ARENA_EVENT_TYPES.bracket
                                        }
                                    >
                                        <SetRewardsBracket />
                                    </Show.When>
                                    <Show.When
                                        isVisible={
                                            eventData?.event_type ==
                                            ARENA_EVENT_TYPES.classicRoulette
                                        }
                                    >
                                        <SetRewardsStandardRoulette />
                                    </Show.When>
                                    <Show.When
                                        isVisible={
                                            eventData?.event_type ==
                                            ARENA_EVENT_TYPES.russianRoulette
                                        }
                                    >
                                        <SetRewardsRussian />
                                    </Show.When>
                                </Show>
                            </Show.When>
                        </Show>
                    </div>
                    <div className='p-32px gap-18px row w-100 mx-auto'>
                        <Show>
                            <Show.When
                                isVisible={
                                    currentActiveStepName == ARENA_EVENT_STEPS.addParticipants
                                }
                            >
                                <div className='col-sm p-0'>
                                    <CustomButton2
                                        disabled={getUserBasedOnOfficeAndPositionLoading}
                                        buttonLabel={`View Participants (${
                                            eventData?.users?.length ?? 0
                                        })`}
                                        buttonSize={BUTTON_SIZE.xl}
                                        buttonType={BUTTON_TYPE.secondaryColor}
                                        width={'100'}
                                        onClick={() => setViewParticipants(true)}
                                    />
                                </div>
                            </Show.When>
                        </Show>
                        <div className='col-sm p-0'>
                            <CustomButton2
                                disabled={
                                    getUserBasedOnOfficeAndPositionLoading || saveEventDataLoading
                                }
                                buttonLabel={buttonLabel}
                                buttonSize={BUTTON_SIZE.xl}
                                buttonType={BUTTON_TYPE.primary}
                                onClick={onNextPress}
                                width={100}
                            />
                        </div>
                    </div>
                </div>
            </CustomModal2>
        </>
    )
}
export default AddArenaEvent
