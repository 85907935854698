import {Fragment} from 'react'
import {Tooltip} from 'primereact/tooltip'
import {useMemo} from 'react'
import {v4 as uuidv4} from 'uuid'
import './TooltipStyles.css'

const CustomTooltip = ({
    title = '',
    style,
    children,
    className = '',
    position = 'bottom',
    disabled = false,
    id = '',
    bgTransparent = false,
}) => {
    const uniqueTarget = useMemo(() => {
        return `tooltipTarget-${uuidv4()}`
    }, [])
    return children ? (
        <>
            <Tooltip
                className={`${bgTransparent ? 'transparent' : ''}`}
                id={id}
                target={`.${uniqueTarget}`}
                position={position}
                disabled={disabled}
            >
                <span style={{fontSize: 14, ...style}} className={className}>
                    {title}
                </span>
            </Tooltip>
            <div className={`${uniqueTarget} d-flex align-items-center w-100`}>{children}</div>
        </>
    ) : null
}

export default CustomTooltip
